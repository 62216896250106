<template>
  <div class="music-body" @click="bindMusicChange()" v-if="src">
    <div :class="{'music-play':audioPlay}"></div>
  </div>
</template>
<script>
import {Howl} from 'howler';
import {config} from "@/js/config";

export default {
  name: 'YdMusic',
  data() {
    return {
      url: '', //mp3地址
      audioPlay: false, //播放状态
      soundBgm: null, //audio js
    }
  },
  props: ['src'],
  created() {
    this.initMusic()
  },
  methods: {
    /** audio定义 */
    initMusic() {
      if (this.src) {
        const soundUrl = config.imgUrlHost + this.src
        this.soundBgm = new Howl({
          src: [soundUrl],
          loop: true, //自动播放
          preload: true,
        })
        // 播放（强制自动播放）
        this.soundBgm.on('load', () => {
          window.WeixinJSBridge &&
          window.WeixinJSBridge.invoke(
              'getNetworkType',
              {},
              () => {
                this.soundBgm.play();
                this.audioPlay = true;
              },
              false,
          );
        });
      }
    },
    /** 视频播放 */
    bindMusicChange() {
      if (this.audioPlay) {
        // 暂停
        this.soundBgm.pause()
      } else {
        // 播放
        this.soundBgm.play()
      }
      this.audioPlay = !this.audioPlay
    },
  }, beforeDestroy() {
    if (this.soundBgm) {
      // 销毁
      this.soundBgm.unload()
    }
  },
}
</script>
<style scoped lang="less">
.music-body {
  position: absolute;
  top: 1rem;
  right: 0.3rem;
  z-index: 9;
  width: 0.7rem;
  height: 0.7rem;
  background-color: rgba(0, 0, 0, .3);
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: top .1s;
  -o-transition: top .1s;
  transition: top .1s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  div {
    width: 100%;
    height: 100%;
    background-image: url('../assets/music_close.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
  }

  .music-play {
    background-image: url('../assets/music_open.png');
    -webkit-animation: aim_move 5s linear infinite;
    animation: aim_move 5s linear infinite;
    @keyframes aim_move {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
