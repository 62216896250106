<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import store from '@/store/store';
import {AuthUtil, LocalStorageUtil, StorageUtil} from '@/js/auth';
import {commonUtil} from '@/js/commonUtil';
import {api} from './js/api';
import {ajaxUtil} from './js/ajaxUtil';

//获取活动模板等基本信息
initSourceIdFromUrl();


function getActivityInfo() {
  let actId = store.state.activityId;
  let req = new XMLHttpRequest();
  let baseUrl = process.env.VUE_APP_API_URL;
  let apiUrl = api.activity.get.replace('{activityId}', actId);
  req.open('GET', baseUrl + apiUrl, false);
  req.setRequestHeader("Content-Type", 'application/json');
  req.send(null);
  let res = JSON.parse(req.responseText);
  if (res != null && res.status === 200) {
    let data = res.data;
    if (data != null) {
      let {name, startTime, endTime, modelNum, wxUrl} = data
      let isWeChat = commonUtil.isWeChat();
      if (isWeChat === 2 || isWeChat === 3) {
        store.setActivity(data);
        let user = LocalStorageUtil.getObject('userLocal');
        if (user && user.wxOpenId) {
          document.title = name;
          store.setUser(user);
          store.setThemes(data);
          store.setTemplateId(modelNum);
          //添加活动访问
          addVisitRecord(actId, startTime, endTime)
        } else {
          //微信授权登录
          AuthUtil.wechatAuthLogin(false, isAuthLogin => {
          });
        }
      } else {
        document.title = name;
        store.setActivity(data);
        store.setThemes(data);
        store.setTemplateId(modelNum);
        //添加活动访问
        addVisitRecord(actId, startTime, endTime)
      }
    }
  }

}

function initSourceIdFromUrl() {//初始化访问资源id
  //把最初进入 页面的url和参数保留用于后续微信分享
  LocalStorageUtil.storeString("justEnterUrl", window.location.href);
  let res = window.location.pathname;
  //判断是否未企业微信
  const ua = window.navigator.userAgent.toLowerCase();
  if ((ua.match(/wxwork/i) == 'wxwork') && !res.includes('/wxWork')) {
    let baseUrl = window.location.protocol + '//' + window.location.host + '/wxWork?actUrl='
    baseUrl += encodeURIComponent(window.location.href)
    window.location.replace(baseUrl)
    return
  }
  let params = commonUtil.getUrlParams();
  let storedState = null;
  if (StorageUtil.getObject('state')) {
    storedState = StorageUtil.getObject('state');
  }
  if (res.includes('/topic')) {//如果是专题页, 则获取专题id
    let topicId = 0;
    if (params.topicId) {
      topicId = params.topicId;
    } else {
      topicId = storedState == null ? 0 : storedState.topicId;
      if (!topicId) {
        topicId = 0;
      }
    }
    store.setTopicId(topicId);
  } else {//如果是活动页，则获取活动ID
    let activityId = 0;
    if (params.activityId != null && params.activityId > 0) {
      activityId = params.activityId;
    } else {
      activityId = storedState == null ? 0 : storedState.activityId;
      if (!activityId) {
        activityId = 0;
      }
    }
    if (params.openId) {
      const user = {wxNickName: params.nickname, wxOpenId: params.openId, token: params.token}
      store.setUser(user);
      LocalStorageUtil.storeObject('userLocal', user)
    }
    if (params.p) {
      let playerInfo = params.p.split('_');
      StorageUtil.storeObject('playerPage', {id: playerInfo[0], groupId: playerInfo[1]})
      StorageUtil.storeString('isJumpPlayer', 'true')
    }
    store.setActivityId(activityId);
    getActivityInfo();
  }
}

function addVisitRecord(actId, startTime, endTime) {
  let nowTime = new Date().getTime();
  if (actId && nowTime > startTime && endTime > nowTime) {
    let {wxNickName, wxOpenId} = store.state.user
    let visit = {activityId: actId, wxNickName: wxNickName, wxOpenId: wxOpenId, viewType: 0}
    ajaxUtil.ajaxJson(api.visit.addVisit, visit, 'POST');
  }
}

export default {}

</script>

<style lang="less">
#app, html, body {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  #ydNav, body {
    width: 6.4rem !important;
    margin-left: auto !important;
    margin-right: auto !important
  }
}

h1, h2, html, body, button, input {
  margin: 0;
  padding: 0;
}

body {
  &::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
}

html {
  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;
}

#app {
  &::-webkit-scrollbar {
    width: 0;
  }

  scrollbar-width: none;
}
</style>
