<template>
  <div class='error'>
      <van-empty image="error" description="无效的数据" />
  </div>
</template>

<script>
export default {
  name:'error',
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style scoped lang='less'>
.error{
    width: 100%;
    height:auto;
    padding: 40px 40px;
    box-sizing: border-box;
}
</style>