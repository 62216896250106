<template>
  <div class='topic' :style="topicBackground">
      <div v-if="topic.bannerUrl!=null">
          <img :src="topic.bannerUrl | appendImgUrl" class="topic-img">
      </div>
      <div v-for="(item,index) in topic.activities" :key="index" class="act-list" v-on:click="clickActivity(item.activity.id)">
          <div class='act-item' v-if="item.showActivity">
              <h1 class='act-title'>{{item.activity.name}}</h1>
              <div class='act-img' v-if="topic.showActivityBanner && item.activity.bannerUrl!=null">
                  <img :src="item.activity.bannerUrl | appendImgUrl">
              </div>
              <div class='act-number'>
                  <div>
                      <p>{{item.activity.voteNum + item.activity.virtualVoteNum}}</p>
                      <p>总{{item.activity.votesUnit}}数</p>
                 </div>
                  <div>
                      <p>{{item.activity.playerNum}}</p>
                      <p>{{item.activity.playerAppellation}}数</p>
                 </div>
                  <div>
                      <p>{{item.activity.viewNum + item.activity.virtualViewNum}}</p>
                      <p>访问量</p>
                 </div>
              </div>
          </div>
      </div>

  </div>
</template>

<script>
    import store from '@/store/store'
    import {api} from '@/js/api'
    import {ajaxUtil} from '@/js/ajaxUtil'
    import {config} from '@/js/config'
    import {AuthUtil} from "@/js/auth";
export default {
  name:'topic',
  data() {
    return {
        topic:'',
        topicBackground:{'background-color': 'white'},
       // settingShowElement: store.state.activity.settingShowElement,
    }
  },
  created() {
      this.getTopic();
  },
  mounted() {

  },
  methods: {
      getTopic(){
          let url = api.topic.getDetail.replace('{id}',store.state.topicId);
          ajaxUtil.ajaxJson(url, null, 'GET').then(data=>{
               this.topic = data;
               document.title = data.title;
              if(data.backgroundColor!=null && data.backgroundColor!= ''){
                  this.topicBackground = {'background-color': data.backgroundColor}
              }
              //微信分享
              store.state.topic=data;
              AuthUtil.weChatShareConfig();
          }, err=>{
              this.$router.push("/error");
          })
      },
      clickActivity(id){
          let url = config.service_host + '/activity?activityId=' + id;
          window.location.href=url;
      }
  }
}
</script>
<style scoped lang='less'>
.topic{
    width: 100%;
    min-height:100%;
    /*padding: 40px 40px;*/
    box-sizing: border-box;

    .topic-img{
        width: 100%;
    }

    .act-list{
        margin-left: 0px;
        margin-right: 0px;
        padding: 20px 20px 20px 20px;
    }

    .act-item{
      width: 100%;
      border-radius: 10px;
      margin-bottom: 30px;
      padding: 20px 0 30px;
      box-shadow: 1px 0px 10px rgba(0,0,0,0.2);
      background-color: white;
      .act-title{
          font-size: 32px;
          margin-bottom: 20px;
          text-align: center;
          line-height: 48px;
      }
      .act-img{
          width: 100%;
          height: 400px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 0 20px;
          img{
              width: 100%;
              height:100%;
              text-align: center;
          }
      }
      .act-number{
          display: flex;
          justify-content: space-around;
          align-items: center;
          font-size: 28px;
          p{
              text-align: center;
              line-height: 40px;
          }
      }
    }
}
</style>
