import {StorageUtil} from '@/js/auth'
import {commonUtil} from '@/js/commonUtil'

const store = {
    state: {
        topicId: 0,
        activityId: 0,
        sldWx: '',
        routeIndex: 0,
        user: {wxNickName: '', wxOpenId: '', token: ''}, //当前登陆用户信息
        activity: null,
        topic: null,
        selectedPlayers: [], //多选投票时选中的选手id列表,数据结构：[{id:1, groupId: 2}]
        themes: {
            globalColor: '',//全局的颜色
            backgroundColor: '',//背景颜色
            backgroundImages: '',//背景图片
            borderImageUrl: '',//边框图片
            rankTabbarNumber: 3,//排行榜tabbar 一列展示几个nav
            templateID: 0,//模板id
            playerColumn: 2,//投票页面选手展示列数
            playerDirection: 1,// 1 左右展示 2 上下展示
            playerImgShowStyle: 3,//1 九宫格 2 轮播 3 竖排
            hasCustomStyle: false,//自定义样式
        },
        currentRoute: '',//当前路由的名称
        groups: [], //分组信息
        searchKey: '', //选手搜索
        searchStatus: false, //搜索时间状态量，变化时执行搜索
        uuid: commonUtil.generateUuid(), //全局uuid，进入即生成
        refreshStatisticData: false, //变化即刷新统计活动数据
        isShowBanner: false,//是否显示轮播图
        isShowEject: false,//是否显示弹出广告
    },

    //由于刷新页面数据会丢失，因此需要重新赋值一遍
    setState() {
        let data = StorageUtil.getObject('state');
        this.state = {...data}
    },
    //设置当前路由的名称
    setRoute(name) {
        store.state.currentRoute = name;
        storeState('currentRoute', name)
    },
    //设置当前模板id
    setTemplateId(id) {
        store.state.themes.templateID = id;
        storeState('templateID', id)
    },

    //设置当前登陆用户信息
    setUser(user) {
        store.state.user = user;
        storeState('user', user)
    },

    //设置当前topicId
    setTopicId(topicId) {
        store.state.topicId = topicId;
        storeState('topicId', topicId)
    },

    //设置当前 activityId
    setActivityId(activityId) {
        store.state.activityId = activityId;
        storeState('activityId', activityId)
    },
    setSldWx(sldWx) {
        store.state.sldWx = sldWx;
        storeState('sldWx', sldWx)
    },

    //设置当前 activity
    setActivity(activity) {
        store.state.activity = activity;
        storeState('activity', activity)
    },

    //设置当前 groups
    setGroups(groups) {
        store.state.groups = groups;
        storeState('groups', groups)
    },
    //设置当前活动的色彩配置等
    setThemes(data) {
        store.state.themes.globalColor = data.settingStyle.backgroundColor;//全局的颜色 TODO:页面上有背景图和背景色的设置，这里的全局色表示什么？
        //store.state.themes.backgroundColor = data.settingStyle.backgroundColor;//背景颜色
        store.state.themes.backgroundImages = data.settingStyle.backgroundImageUrl;
        store.state.themes.borderImageUrl = data.settingStyle.borderImageUrl;
        store.state.themes.rankTabbarNumber = data.settingShowVoting.playerGroupColumns;//排行榜和投票页group 一列展示几个nav
        store.state.themes.templateID = data.modelNum;//模板id
        store.state.themes.playerColumn = data.settingShowVoting.playerColumns;//投票页面选手展示列数
        store.state.themes.playerDirection = data.settingShowVoting.playerShowDirection.index;
        store.state.themes.playerImgShowStyle = data.settingShowPlayer.playerImagesShowType.index;//1 九宫格 2 轮播 3 竖排
        store.state.themes.hasCustomStyle = data.settingShowElement.hasCustomStyle;
        storeState('themes', store.state.themes)
    },

}
//由于刷新页面store里面的值会丢失，因此在
//浏览器存储一份，防止state里面数据丢失
function storeState(name, data) {
    let store = {};
    if (StorageUtil.getObject('state')) {
        store = StorageUtil.getObject('state');
    }
    store[name] = data;
    StorageUtil.storeObject('state', store)
}

export default store
