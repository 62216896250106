<template>
  <div v-show="state.themes.templateID" :id="'act'+state.activityId" style="width: 100%;height: 100%;">
    <yd-ad-start v-if="settingAd.hasStart" :ad="settingAd" @close="settingAd.hasStart=false"></yd-ad-start>
    <div v-show="!settingAd.hasStart" id="home" :style="tpStyle" :class="tpClass">
      <!--通知-->
      <div class="top-notice">
        <van-notice-bar v-if="notice" :text="notice" mode="closeable" left-icon="volume-o"
                        color="#fff" background="rgba(0,0,0,.1)" scrollable/>
      </div>
      <!--背景音乐-->
      <yd-music :src="settingStyle.backgroundMusicUrl"></yd-music>
      <yd-banner v-if="isShowBanner&&settingShufflingImages.length" :banners="settingShufflingImages"/>
      <!--导航-->
      <yd-nav></yd-nav>
      <div class='render'>
        <router-view></router-view>
      </div>
      <!--版权-->
      <yd-copyright :copyright="settingShowBottom"></yd-copyright>
      <!--底部广告-->
      <div class="bottom-ad" v-if="settingAd.hasBottom">
        <div class="ad-pane">
          <div class="ad-mask" @click="settingAd.hasBottom=false">
            <div>广告</div>
            <div>
              <van-icon name="cross"/>
            </div>
          </div>
          <img :src="settingAd.bottomAd | appendImgUrl" alt="" @click="bindAdLinkChange(settingAd.bottomLink)">
        </div>
      </div>
      <!--搜索-->
      <tp-search></tp-search>
      <!--置顶按钮-->
      <div id='backTop' @click='onBackTop' v-show='isShowTop'></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import YdNav from '@/components/nav.vue'
import YdBanner from '@/components/banner.vue'
import YdMusic from '@/components/music.vue'
import YdCopyright from '@/components/copyright.vue'
import YdAdStart from '@/components/ad/start.vue'

import store from '../store/store.js'
import {config} from '@/js/config'
import {AuthUtil} from "@/js/auth";
import less from "less";

//活动数据组件
let tpId = store.state.themes.templateID;
let actId = store.state.activityId;
let hasCustomStyle = store.state.themes.hasCustomStyle;
let tpHeader = () => import("@/components/template/model" + tpId + "/header.vue")
Vue.component('tpHeader', tpHeader)
let tpSearch = () => import("@/components/template/model" + tpId + "/search.vue")

Vue.component('tpSearch', tpSearch)
export default {
  name: 'index',
  data() {
    return {
      templateId: tpId,
      state: store.state,
      tpClass: 'tp-class' + tpId,//模板样式
      tpStyle: '',
      notice: '',
      settingStyle: {},//基础设置
      settingShufflingImages: [],//背景图集合
      settingShowBottom: {},//底部设置 导航 和 版权
      settingAd: {},//广告
      isShowBanner: false,//是否显示轮播图 存在特殊页面
      isShowTop: false,//是否显示置顶按钮,
      isShowEject: false,//是否出现置顶按钮,
    }
  },
  components: {
    YdNav,
    YdBanner,
    YdMusic,
    YdCopyright,
    YdAdStart,
  },
  watch: {
    'state.isShowBanner': function (newVal, oldVal) {
      this.isShowBanner = newVal
    },
  },
  beforeCreate() {
    require(`@/style/common.less`)
    if (tpId){
      require(`@/components/template/model${tpId}/style.less`)
    }
  },
  created() {
    let {settingStyle, settingShufflingImages, settingShowBottom, notice, settingAd} = store.state.activity;
    this.tpStyleFilter(settingStyle)
    this.notice = notice
    this.settingAd = settingAd
    this.settingShufflingImages = settingShufflingImages
    this.settingShowBottom = settingShowBottom
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
    AuthUtil.weChatShareConfig()
  },
  methods: {
    tpStyleFilter(settingStyle) {
      let tpStyle = {}
      let {backgroundImageUrl,backgroundColor} = settingStyle;
      if (backgroundImageUrl) {
        tpStyle.backgroundImage = 'url(' + config.imgUrlHost + backgroundImageUrl + ')'
        tpStyle.backgroundColor = 'transparent'
      }
      if (backgroundColor){
        document.documentElement.style.setProperty('--global-color-t'+tpId, backgroundColor); // 更改主色为红色
      }
      this.tpStyle = tpStyle;
      this.settingStyle = settingStyle
    },
    handleScroll() {
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      this.isShowTop = scrollTop > 50 ? true : false;
    },
    onBackTop() {
      scrollTo(0, 0);
    },

    onSearch(key) {
      this.isShowSearchBox = false;
      if (this.$router.currentRoute.path !== '/activity/vote') {
        this.$router.push({path: 'vote', query: {searchKey: key}})
      }
    },
    bindAdLinkChange(link) {
      if (link) {
        window.location.href = link;
      }
    },
  }
}
</script>
<style scoped lang='less'>
#home {
  width: 100%;
  min-height: 100%;
  position: relative;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .top-notice {
    z-index: 9;
    position: fixed;
    top: 0;
    width: 100%;
  }

  //关闭背景
  .van-popup {
    background-color: transparent;
  }

  .bottom-ad {
    box-sizing: border-box;
    padding: 0.18rem;

    .ad-pane {
      position: relative;
      width: 100%;
      height: auto;

      .ad-mask {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        width: 1.2rem;
        font-size: 0.2rem;
        line-height: 0.2rem;
        color: #fff;

        div {
          background-color: rgba(0, 0, 0, 0.5);
          padding: 0.08rem;
          box-sizing: border-box;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.search-mask {
  background-color: transparent !important;
}

.view-router {
  width: 100%;
  height: auto;
}

#backTop {
  position: fixed;
  bottom: 30%;
  right: 20px;
  width: 60px;
  height: 60px;
  background: rgba(0, 0, 0, .5) url('../assets/backTop.png') no-repeat center center;
  background-size: 80% 80%;
  border-radius: 50%;
  z-index: 999;

}
</style>
