import store from '../store/store'
import {api} from "@/js/api";
import {ajaxUtil} from '@/js/ajaxUtil'
import {commonUtil} from '@/js/commonUtil'
import {config} from '@/js/config'
import wx from 'weixin-js-sdk';

let StorageUtil = {
    storeString: function (key, value) {
        sessionStorage.setItem(key, value);
    },

    storeObject: function (key, object) {
        sessionStorage.setItem(key, JSON.stringify(object));
    },

    getString: function (key) {
        return sessionStorage.getItem(key);
    },

    getObject: function (key) {
        return JSON.parse(sessionStorage.getItem(key));
    },

    removeObject: function (key) {
        sessionStorage.removeItem(key);
    }
}
let LocalStorageUtil = {
    storeString: function (key, value) {
        localStorage.setItem(key, value);
    },

    storeObject: function (key, object) {
        localStorage.setItem(key, JSON.stringify(object));
    },

    getString: function (key) {
        return localStorage.getItem(key);
    },

    getObject: function (key) {
        return JSON.parse(localStorage.getItem(key));
    },

    removeObject: function (key) {
        localStorage.removeItem(key);
    }
}
let AuthUtil = {
    getToken: function () {
        return store.state.token
    },
    setToken: function (val) {
        store.setToken(val)
    },
    removeToken: function () {
        store.removeToken()
    },
    /**
     * 微信授权登录
     * @param isDialog:手机浏览器是否弹出提醒框;
     * @param callback 回调方法
     */
    wechatAuthLogin: function (isDialog, callback) {
        let activityId = store.state.activityId;
        //是否提醒微信打开
        let isWeChat = commonUtil.isWeChat();
        let isWxBrowser = isWeChat === 2 || isWeChat === 3;
        if (!isWxBrowser && isDialog) {
            callback(false);
            return;
        }

        //投票处检查如果有user
        let storeUser = store.state.user;
        if (!storeUser.wxOpenId) {
            let userLocal = LocalStorageUtil.getObject('userLocal');
            if (userLocal && userLocal.wxOpenId) {
                storeUser = userLocal
                store.setUser(userLocal);
            }
        }
        if (storeUser.wxOpenId) {
            callback(true);
            return;
        }
        let urlPath = window.location.pathname;
        //授权页面和用户不存在并且是微信浏览器打开
        let noAuthPage = (urlPath.indexOf('/wxAuth') === -1) && (urlPath.indexOf('/wxAuthCallBack') === -1);
        //是否需要微信授权登录
        if (noAuthPage && isWxBrowser && activityId) {
            let hostname = window.location.hostname;
            let hostnameInfo = hostname.split('.');
            const appid = process.env.VUE_APP_WX_APPID;
            let redirectUri = window.location.protocol + '//wx.' + process.env.VUE_APP_WX_BACK + '/wxAuthCallBack?activityId=' + activityId + '&sld=' + hostnameInfo[0]
            // let redirectUri = window.location.protocol + '//' + process.env.VUE_APP_WX_BACK + '/wxAuthCallBack?activityId=' + activityId + '&sld=' + hostnameInfo[0]
            let playerPage = StorageUtil.getObject('playerPage')
            if (playerPage) {
                redirectUri += '&p=' + playerPage.id + '_' + playerPage.groupId
            }
            let authorizeUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appId='
            authorizeUrl += appid
            authorizeUrl += '&redirect_uri='
            authorizeUrl += encodeURIComponent(redirectUri)
            authorizeUrl += '&response_type=code&scope=snsapi_userinfo#wechat_redirect'
            window.location.replace(authorizeUrl);
        }
    },
    /**
     * 微信分享
     */
    weChatShareConfig: function () {
        //获取签名
        this.getWeChatConfig();
    },
    getWeChatConfig: function () {
        //最初进入项目时的url
        let initUrl = window.location.href;
        //当前页面url
        let currUrl = window.location.href;
        let justEnterUrl = LocalStorageUtil.getString("justEnterUrl");
        let isWeChat = commonUtil.isWeChat();
        //手机微信才有这个初始化url问题,其他端无问题
        if (justEnterUrl && isWeChat === 2) {
            initUrl = justEnterUrl;
        }
        let isAuthPage = currUrl.indexOf('wxAuth') !== -1 || currUrl.indexOf('wxAuthCallBack') !== -1;
        let isWxBrowser = isWeChat !== 0;
        if (!isWxBrowser || isAuthPage) {
            return;
        }
        let share = {}
        if (justEnterUrl.includes('/topic')) {
            initUrl = window.location.href;
            share.activityShareTitle = store.state.topic.title
            share.shareContent = initUrl
            share.h5Url = initUrl
            share.shareImageUrl = store.state.topic.bannerUrl
        } else {
            let {settingShare, h5Url, settingShufflingImages, name, id} = store.state.activity
            if (settingShare) {
                share = settingShare
            }
            if (!share.h5Url) {
                share.h5Url = h5Url;
            }
            if (!share.activityShareTitle) {
                share.activityShareTitle = name;
            }
            if (!share.shareContent) {
                share.shareContent = '快来为您喜欢的选手投票吧！';
            }
            if (!share.shareImageUrl) {
                if (settingShufflingImages && settingShufflingImages.length) {
                    share.shareImageUrl = settingShufflingImages[0].shufflingImageUrl;
                }
            }
        }
        //获取签名
        ajaxUtil.ajaxJson(api.wechat.signature, {url: initUrl.split("#")[0]}, 'POST').then(data => {
            wx.config({
                debug: false, // 开启调试模式
                appId: data.appId, //appId
                timestamp: data.timestamp, //生成签名的时间戳
                nonceStr: data.nonceStr, // 生成签名的随机串
                signature: data.signature,//签名
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 需要使用的JS接口列表
            });
            wx.ready(function () {
                //分享到朋友圈
                wx.updateTimelineShareData({
                    title: share.activityShareTitle, // 分享标题
                    link: share.h5Url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: config.imgUrlHost + share.shareImageUrl, // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    }
                })
                //分享给朋友
                wx.updateAppMessageShareData({
                    title: share.activityShareTitle, // 分享标题
                    desc: share.shareContent, // 分享描述
                    link: share.h5Url + '&t=' + new Date().getTime(), // 分享链接，
                    imgUrl: config.imgUrlHost + share.shareImageUrl, // 分享图标
                    type: 'link', // 分享类型,music、video或link，不填默认为link
                    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    }
                });
            });
            wx.error(function (res) {
                console.log('生成微信签名失败了');
            })
        })
    },
    getSpecifyUuid: function (type) { //获取特殊指定业务的uuid，在全局uuid的基础上加上type
        return store.state.uuid + type;
    },
    activityShare() {
        //最初进入项目时的url
        let initUrl = window.location.href;
        let isWeChat = commonUtil.isWeChat();
        if (!isWeChat || initUrl.includes('/wxAuth') || initUrl.includes('/wxAuthCallBack')) {
            return;
        }
        let justEnterUrl = LocalStorageUtil.getString("justEnterUrl");
        //手机微信才有这个初始化url问题,其他端无问题
        if (justEnterUrl && isWeChat === 2) {
            initUrl = justEnterUrl;
        }

        let share = {
            initUrl: initUrl
        }
        if (justEnterUrl.includes('/topic')) {
            initUrl = window.location.href;
            share.title = store.state.topic.title
            share.desc = '快快来参加！！！'
            share.link = initUrl
            share.imgUrl = store.state.topic.bannerUrl
        } else {
            let {settingShare, h5Url, settingShufflingImages, name, id} = store.state.activity
            if (settingShare) {
                share.title = settingShare.activityShareTitle
                share.desc = settingShare.shareContent
                share.imgUrl = settingShare.shareImageUrl
            }
            share.link = h5Url;
            if (!share.title) {
                share.title = name;
            }
            if (!share.desc) {
                share.desc = '快来为您喜欢的选手投票吧！';
            }
            if (!share.imgUrl) {
                if (settingShufflingImages && settingShufflingImages.length) {
                    share.imgUrl = settingShufflingImages[0].shufflingImageUrl;
                }
            }
        }
        this.shareConfig(share)
    },
    playerShare(player) {
        let initUrl = window.location.href;
        let isWeChat = commonUtil.isWeChat();
        if (!isWeChat || initUrl.includes('/wxAuth') || initUrl.includes('/wxAuthCallBack')) {
            return;
        }
        let justEnterUrl = LocalStorageUtil.getString("justEnterUrl");
        //手机微信才有这个初始化url问题,其他端无问题
        if (justEnterUrl && isWeChat === 2) {
            initUrl = justEnterUrl;
        }

        let share = {
            initUrl: initUrl
        }

        let {settingShare, h5Url, name, settingShufflingImages} = store.state.activity
        if (settingShare) {
            share.title = settingShare.playerShareTitle
            share.desc = settingShare.shareContent
            share.imgUrl = settingShare.shareImageUrl
        }
        share.link = h5Url + '&p=' + player.id + '_' + player.groupId;
        if (!share.title) {
            share.title = '我是' + player.num + '号' + player.name + ',正在参加' + name;
        }
        if (!share.desc) {
            share.desc = '快来为您喜欢的选手投票吧！';
        }
        if (!share.imgUrl) {
            if (settingShufflingImages && settingShufflingImages.length) {
                share.imgUrl = settingShufflingImages[0].shufflingImageUrl;
            }
        }
        this.shareConfig(share)
    },
    shareConfig(share) {
        //获取签名
        ajaxUtil.ajaxJson(api.wechat.signature, {url: share.initUrl.split("#")[0]}, 'POST').then(data => {
            wx.config({
                debug: false, // 开启调试模式
                appId: data.appId, //appId
                timestamp: data.timestamp, //生成签名的时间戳
                nonceStr: data.nonceStr, // 生成签名的随机串
                signature: data.signature,//签名
                jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 需要使用的JS接口列表
            });
            wx.ready(function () {
                //分享到朋友圈
                wx.updateTimelineShareData({
                    title: share.title, // 分享标题
                    link: share.link + '&t=' + new Date().getTime(), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: config.imgUrlHost + share.imgUrl, // 分享图标
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    }
                })
                //分享给朋友
                wx.updateAppMessageShareData({
                    title: share.title, // 分享标题
                    desc: share.desc, // 分享描述
                    link: share.link + '&t=' + new Date().getTime(), // 分享链接，
                    imgUrl: config.imgUrlHost + share.imgUrl, // 分享图标
                    type: 'link', // 分享类型,music、video或link，不填默认为link
                    dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                    success: function () {
                        // 用户点击了分享后执行的回调函数
                    }
                });
            });
            wx.error(function (res) {
                console.log('生成微信签名失败了');
            })
        })
    },
}
export {StorageUtil, LocalStorageUtil, AuthUtil};
