<template>
  <div class='playerDetail' id='el'>
    <div class="yd-back" @click="clickBack"></div>
    <tp-player v-if="player" :player="player" :config="config" :isSel="isSelected"
               @onHelp="helpVote"
               @onVote="onVote"/>
    <yd-poster :show='isShow' :player="player" @close='isShow = false'></yd-poster>
    <div class='yd-vote-choose' v-if="selectedPlayers.length>0">
      <div class="choose-pane">
        <div class='choose-text' v-if="voteRules.votingScopeType.index==1">
          已选<span class="choose-num"> {{ selectedPlayers.length }} </span>项,
          最少<span class="choose-num"> {{ voteRules.minVotingPlayers }} </span>项,
          最多<span class="choose-num"> {{ voteRules.maxVotingPlayers }} </span>项
        </div>
        <div class='choose-text' v-else>
          全部已选<span class="choose-num"> {{ selectedPlayers.length }} </span> 项
        </div>
        <button class='choose-btn' v-on:click="commitMultiVote">
          {{ voteButtonName }}
        </button>
      </div>
    </div>
    <!-- 验证码弹出 -->
    <yd-ad-eject :show="isShowEject" :eject="settingAd.ejectAd"
                 :link="settingAd.ejectLink" @close="isShowEject=false">
    </yd-ad-eject>
    <yd-vote-captcha :show="showValidator" @onCancel="showValidator=false" @onSubmit="submitVote"></yd-vote-captcha>
    <AuthWxDialog :showDialog="showWxDialog" @onCancel="showWxDialog=false"></AuthWxDialog>
  </div>
</template>

<script>
import store from '../store/store.js'
import YdPoster from '../components/poster.vue'
import YdVoteCaptcha from '@/components/captcha.vue'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {commonUtil} from '@/js/commonUtil'
import {AuthUtil, LocalStorageUtil, StorageUtil} from '@/js/auth'
import {encode} from '@/js/aesencryp'
import AuthWxDialog from "@/components/authWxDialog";
import YdAdEject from "@/components/ad/eject.vue";


//引入海报组件
let tempID = store.state.themes.templateID;
//引入选手信息基本信息
let TpPlayer = () => import("@/components/template/model" + tempID + '/player.vue')
export default {
  name: 'playerDetail',
  data() {
    return {
      activity: store.state.activity,
      theme: store.state.themes,
      isShow: false,
      posterArr: [],
      player: '',
      config: {},
      voteRules: store.state.activity.settingVotingRules,
      groups: [],
      isSelected: false, //多选时是否选中
      selectedPlayers: store.state.selectedPlayers,
      finishedPosterCount: 0,
      voteButtonName: '',
      playerId: 0,
      showValidator: false,
      playerIds: [], //要提交的player ids
      groupIdInParam: 0,
      showWxDialog: false,
      infoConfig: {},
      settingAd: {},//广告
      isShowEject: false,
    }
  },
  components: {
    YdAdEject,
    YdPoster,
    TpPlayer,
    YdVoteCaptcha,
    AuthWxDialog
  },
  created() {
    StorageUtil.removeObject('isJumpPlayer')
    let playerInfo = StorageUtil.getObject('playerPage');
    //初始化配置
    let {settingShowPlayer, settingShowElement, settingAd} = store.state.activity;
    this.config = settingShowPlayer
    this.settingAd = settingAd
    let {votingButtonName, votesUnit} = settingShowElement
    this.config.voteButtonName = votingButtonName
    this.config.votesUnit = votesUnit
    this.voteButtonName = votingButtonName
    //获取详情信息
    this.groupIdInParam = playerInfo.groupId;
    this.playerId = playerInfo.id;
    this.getPlayerInfo(this.playerId);

    //设置选中状态
    if (this.config.hasVotingButton) {
      if (commonUtil.findIndexFromArrayById(this.selectedPlayers, this.playerId) > -1) {
        this.isSelected = true;
      } else {
        this.isSelected = false;
      }
    }

    //获取groups
    this.groups = store.state.groups;
    if (this.groups.length == 0) {
      this.getGroups();
    }
  },
  mounted() {

  },
  methods: {
    clickBack() {
      let actId = this.activity.id;
      const parentPath = LocalStorageUtil.getString("parentPath");
      this.$router.push({
        path: parentPath === '/activity/rank' ? '/activity/rank' : '/activity/vote',
        query: {activityId: actId}
      });
    },
    getGroups() {
      this.groups = [{id: 0, name: '全部'}];
      let url = api.activity.getGroups.replace('{activityId}', this.activity.id);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        for (var i in data) {
          this.groups.push(data[i]);
        }
        store.setGroups(this.groups);
      })
    },

    getPlayerInfo(id) {
      let url = api.player.getDetail.replace('{id}', id);
      if (this.groupIdInParam > 0) {
        url = url + '&rankByGroup=true';
      }
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        //添加选手访问
        this.addPlayerVisit();
        if (data.content && data.content.indexOf('<img')) {
          const regex = new RegExp('<img', 'gi');
          data.content = data.content.replace(regex, `<img style="max-width:100%;height:auto;"`)
        }

        this.player = data;
        //如果有外部链接，直接跳转
        if (data.detailsType == 1 && data.jumpUrl != null && data.jumpUrl != '') {
          this.$router.go(-1);
          window.location.href = data.jumpUrl;
        }
        AuthUtil.playerShare(data);
      }, err => {
        this.$router.push("/error");
      })
    },

    addPlayerVisit() {//添加选手访问
      let url = api.visit.addVisit;
      let user = store.state.user;
      let req = {activityId: this.activity.id, playerId: this.playerId, wxNickName: '', wxOpenId: '', viewType: 1};
      if (user) {
        req.wxNickName = user.wxNickName;
        req.wxOpenId = user.wxOpenId;
      }
      ajaxUtil.ajaxJson(url, req, 'POST');
    },


    onVote() {
      //微信授权登录
      let _this = this;
      AuthUtil.wechatAuthLogin(true, isAuthOk => {
        if (isAuthOk) {
          _this.vote();
        } else {
          _this.showWxDialog = true;
        }
      })
    },
    vote() {
      if (this.isSelected) {//若当前是已选，则取消选择并完成
        this.cancelSelect();
        return;
      }

      //当状态由未选中改为选中时
      let voteScope = this.voteRules.votingScopeType.index;
      let chooseType = 0;
      let maxVoteNum = 0;

      if (voteScope == 1) { //整个活动（不区分各个分组规则）
        maxVoteNum = this.voteRules.maxVotingPlayers;
        chooseType = this.voteRules.chooseType ? this.voteRules.chooseType.index : null;
        if (chooseType == 1) {//单选时，直接提交
          this.commitSingleVote();
        } else { //多选时，按钮只负责选中,不提交
          //检查是否超出规则限制的最大投票数
          if (this.selectedPlayers.length < maxVoteNum) {
            this.selectedPlayers.push({id: this.player.id, groupId: this.player.groupId});
            this.isSelected = true;
          } else {
            //错误提示
            this.$toast('最多只能选择' + maxVoteNum + '个');
          }
        }
      } else if (voteScope == 2) {//单分组情况下
        //检查已选列表中是否有已选选手，并判断当前选手分组是否和已选选手分组一致，如果不一致，则提示错误
        let currrentSelectedPlayerGroupId = this.selectedPlayers.length > 0 ? this.selectedPlayers[0].groupId : 0;
        if (currrentSelectedPlayerGroupId > 0 && currrentSelectedPlayerGroupId != this.player.groupId) {
          let previousSelectedPlayerGroup = this.groups[commonUtil.findIndexFromArrayById(this.groups, this.selectedPlayers[0].groupId)]
          this.$toast('已选' + previousSelectedPlayerGroup.name + '选手，请先给' + previousSelectedPlayerGroup.name + '投票');
          return;
        }

        //获取选手所在分组的规则
        let group = this.groups[commonUtil.findIndexFromArrayById(this.groups, this.player.groupId)]
        chooseType = group.chooseType ? group.chooseType.index : null;
        maxVoteNum = group.maxVotingPlayers;

        if (chooseType == 1) {//单选时，直接提交
          this.commitSingleVote();
        } else { //多选时，按钮只负责选中或取消
          //检查是否超出规则限制的最大投票数
          if (this.selectedPlayers.length < maxVoteNum) {
            this.selectedPlayers.push({id: this.player.id, groupId: this.player.groupId});
            this.isSelected = true;
          } else {
            //错误提示
            this.$toast('最多只能选择' + maxVoteNum + '个');
          }
        }
      } else if (voteScope == 3) { //多分组的规则下，可以跨分组选择，但必须按各个分组的规则来
        //获取选手所在分组的规则
        let group = this.groups[commonUtil.findIndexFromArrayById(this.groups, this.player.groupId)]
        chooseType = group.chooseType ? group.chooseType.index : null;
        maxVoteNum = group.maxVotingPlayers;

        //检查是否超出当前组规则限制的最大投票数
        let voteNumForCurrentGroup = 0;
        for (var i in this.selectedPlayers) {//查询当前分组下已投票数量
          if (this.selectedPlayers[i].groupId == this.player.groupId) {
            voteNumForCurrentGroup = voteNumForCurrentGroup + 1;
          }
        }
        if (voteNumForCurrentGroup < maxVoteNum) {
          this.selectedPlayers.push({id: this.player.id, groupId: this.player.groupId});
          this.isSelected = true;
        } else {
          //错误提示
          this.$toast('最多只能选择' + maxVoteNum + '个');
        }
      }
    },
    cancelSelect() {//取消选择
      let index = commonUtil.findIndexFromArrayById(this.selectedPlayers, this.player.id);
      if (index > -1) {//从选中数组中删除
        this.selectedPlayers.splice(index, 1);
      }
      this.isSelected = false;
    },

    commitSingleVote() {//单选提交vote
      this.playerIds = [this.playerId];
      this.votePlayers();
    },

    commitMultiVote() { //多选提交vote
      if (this.selectedPlayers.length <= 0) {
        return;
      }
      if (this.checkMultiVoteRule()) {
        this.playerIds = []
        for (let i in this.selectedPlayers) {
          this.playerIds.push(this.selectedPlayers[i].id);
        }
        this.votePlayers();
      }
    },

    votePlayers() {
      if (this.voteRules.verificationCodeProtective) {
        this.showValidator = true;
      } else {
        this.submitVote();
      }
    },

    submitVote() {
      this.showValidator = false;
      let url = api.player.vote;
      let user = store.state.user;
      let regUuid = AuthUtil.getSpecifyUuid("vote");
      let validateToken = encode(regUuid);
      let req = {
        activityId: this.activity.id, playerIds: this.playerIds, wxNickName: user.wxNickName,
        wxOpenId: user.wxOpenId, wxAvatar: user.wxAvatar, validateToken: validateToken
      };
      ajaxUtil.ajaxJson(url, req, 'POST').then(data => {
        if (this.selectedPlayers.length > 0) { //多选情况下，刷新多选数据及页面
          this.selectedPlayers.splice(0, this.selectedPlayers.length);
          //刷新数据
          this.getPlayerInfo(this.playerId);
          this.isSelected = false;
          //刷新活动统计数据
          store.state.refreshStatisticData = store.state.refreshStatisticData ? false : true;
        }
        let hasEject = this.settingAd.hasEject;
        if (hasEject) {
          this.isShowEject = true
        } else {
          this.$toast.success('投票成功！')
        }
      }, err => {
        var errResponse = err.response;
        var errData = errResponse == null ? null : errResponse.data;
        var customError = errData == null ? null : errData.data;
        if (customError == null) {
          this.$toast('投票失败！')
        } else {
          let errorCode = customError.errorCode;
          let definedCodes = [3010, 3001, 3004, 3005, 3006, 3007, 3015, 3016, 3017, 3013, 1206, 1207, 1208, 3012, 3011, 3014, 1055];
          if (definedCodes.indexOf(errorCode) > -1) {
            this.$toast(customError.message);
          } else {
            this.$toast('投票失败！')
          }
        }
      })
    },

    checkMultiVoteRule() {
      let voteScope = this.voteRules.votingScopeType.index;
      let minVoteNum = 0;
      if (voteScope == 1) {//整个活动多选时
        minVoteNum = this.voteRules.minVotingPlayers
        if (this.selectedPlayers.length < minVoteNum) {
          this.$toast('至少选择' + minVoteNum + '项');
          return false;
        }
      } else if (voteScope == 2) {//单个分组多选时

        let groupId = this.selectedPlayers[0].groupId;
        let group = this.groups[commonUtil.findIndexFromArrayById(this.groups, groupId)]
        minVoteNum = group.minVotingPlayers;
        if (this.selectedPlayers.length < minVoteNum) {
          this.$toast(group.name + '至少选择' + minVoteNum + '项');
          return false;
        }
      } else if (voteScope == 3) {//多分组时,检查每个分组的最小数量
        //先计算各分组下已选数量
        let selectedNumForEachGroupId = [];
        for (var i in this.selectedPlayers) {
          let index = commonUtil.findIndexFromArrayById(selectedNumForEachGroupId, this.selectedPlayers[i].groupId);
          if (index > -1) {
            selectedNumForEachGroupId[index].num = selectedNumForEachGroupId[index].num + 1;
          } else {
            selectedNumForEachGroupId.push({id: this.selectedPlayers[i].groupId, num: 1});
          }
        }

        for (var j in selectedNumForEachGroupId) {
          let group = this.groups[commonUtil.findIndexFromArrayById(this.groups, selectedNumForEachGroupId[j].id)];
          if (selectedNumForEachGroupId[j].num < group.minVotingPlayers) {
            this.$toast(group.name + '至少选择' + group.minVotingPlayers + '项');
            return false;
          }
        }
      }
      return true;
    },

    helpVote() {
      this.isShow = true
    },

  },
}
</script>

<style scoped lang='less'>

</style>>
