<template>
  <div class="yd-rank">
    <tp-header :groups="groups" @onGroup="onSelectGroup"></tp-header>
    <div class='list'>
      <tp-rank :load="playerLoading" :ranks="rankList" :finish="loadFinished"
               @onLoad="loadNextPage" @onDetail="clickPlayer"></tp-rank>
    </div>
  </div>
</template>

<script>
import store from '../store/store.js'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {AuthUtil, StorageUtil} from "@/js/auth";
//引入海报组件
let tpId = store.state.themes.templateID;
let tpRank = () => import("@/components/template/model" + tpId + "/rank.vue")

export default {
  name: 'rank',
  data() {
    return {
      actId: '',
      rankList: [],
      groups: [{value: 0, des: '全部'}],
      groupBtnName: '',
      playerLoading: false,
      loadFinished: false,
      pageCount: 30, //固定每页30条
      pageNo: 1,
      selectedGroupId: 0,
      showAllGroup: true, //是否显示所有分组
    }
  },
  computed: {},
  components: {
    tpRank
  },
  created() {
    console.log('rank')
    store.state.isShowBanner = true;
    let {id, settingShowRanking, settingShowElement} = store.state.activity;
    //初始化分组信息
    if (settingShowRanking.hasPlayerGroup) {
      this.showAllGroup = settingShowRanking.showAllGroup;
      //全部按钮名称
      this.groupBtnName = settingShowElement.groupBtnName
    }
    this.actId = id
    this.loadFinished = true;
    this.getGroups();
  },
  mounted() {
    AuthUtil.weChatShareConfig();
  },
  methods: {
    getGroups() {
      this.groups = [];
      let url = api.activity.getGroups.replace('{activityId}', this.actId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        //获取排行数据
        if (this.showAllGroup) {
          //默认选择所有分组
          this.groups.push({id: 0, name: this.groupBtnName ? this.groupBtnName : '全部'});
          this.selectedGroupId = 0;
        } else {
          if (data.length == 0) {
            this.selectedGroupId = 0;
          } else {
            //不显示全部分组时，默认选择第一个分组
            this.selectedGroupId = data[0].id;
          }
        }
        this.pageNo = 1;
        this.getPlayersRanking(this.selectedGroupId, this.pageNo);

        for (var i in data) {
          this.groups.push(data[i]);
        }
        store.setGroups(this.groups);
      })
    },

    onSelectGroup(groupId) {
      this.selectedGroupId = groupId;
      this.pageNo = 1;
      this.loadFinished = false;
      this.getPlayersRanking(groupId, this.pageNo);
    },

    loadNextPage() {
      this.pageNo = this.pageNo + 1;
      this.getPlayersRanking(this.selectedGroupId, this.pageNo);
    },

    getPlayersRanking(groupId, pageNo) {
      let url = api.activity.getPlayerRanking.replace('{activityId}', this.actId).replace('{groupId}', groupId)
          .replace('{pageNo}', pageNo).replace('{pageCount}', this.pageCount);
      this.playerLoading = true;
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        let dataList = data.resultList;
        if (pageNo == 1) {
          this.rankList = [];
        }
        for (var i in dataList) {
          this.rankList.push(dataList[i]);
        }
        this.playerLoading = false;
        this.loadFinished = false;
        if (data.pageNo == data.totalPage || data.resultList.length == 0) {
          this.loadFinished = true;
        }
      }, err => {
        this.playerLoading = false;
        this.loadFinished = true;
      })
    },

    clickPlayer(playerId) {
      let groupId = this.selectedGroupId;
      StorageUtil.storeObject('playerPage', {id: playerId, groupId: groupId})
      this.$router.push({path: 'playerDetail', query: {activityId: this.actId, p: playerId + '_' + groupId, t: new Date().getTime()}})
    },
  }
}
</script>
<style scoped lang='less'>

</style>
