import axios from 'axios'
import Vue from 'vue'
import {AuthUtil} from './auth'
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.prototype.$axios = axios;

const ajaxUtil ={
     /***
     * @url   //请求的url 可以带参数
     * @method //http请求的类型
     * @data  //post的数据 可以为空
     * loading: 是否出现加载动画， true 为不加载，不传值默认加载
     * 使用方法：
     * ajaxUtil.ajaxJson(url,data,'get',true).then(res=>{},err=>{})
    */
    ajaxJson:function(url,data,method,loading){
        //加载动画
        return new Promise((resolve,reject)=>{
            const instance = axios.create({
                //baseUrl: baseUrl,
                timeout:60000,
                responseType:'json',
                headers:{
                 token: AuthUtil.getToken(),
                 'Content-Type':'application/json; charset=UTF-8',
                    'Client-Type':1
                }
            })
            instance({
                url:encodeURI(url),
                method:method,
                data:JSON.stringify(data),})
                .then((res)=>{
                    //加载动画隐藏
                    if(res.data.status == 200){
                        resolve(res.data.data)
                    }else{
                        reject(res.data.data)
                    }
               }).catch(err=>{
                   //加载动画隐藏
                   reject(err)
            })
        })

    },
    /***
    * 登录请求
    *
    *
   */
   ajaxLogin: function(url,data,method){
    return new Promise((resolve,reject)=>{
       const instance = axios.create({
           timeout: 60000,
           responseType:'json',
           headers:{
               'Content-Type':'application/json; charset=UTF-8',
           }
       })
       instance({
           url:encodeURI(url),
           method:method,
           data:JSON.stringify(data)
        })
           .then((res)=>{
               console.log(res)
               if(res.data.status == 200){
                   resolve(res.data.data)
               }else{
                   reject(res.data.data)
               }
           }).catch((err)=>{
               reject(err)
       })
    })
  },
}
export {ajaxUtil}
