<template>
  <div class="yd-raffle">
    <div class="raffle-wheel">
      <!-- 大转盘抽奖 -->
      <lucky-wheel
          ref="myLucky"
          width="5rem"
          height="5rem"
          :prizes="prizes"
          :blocks="blocks"
          :buttons="buttons"
          @start="startCallback"
          @end="endCallback"
      />
    </div>
    <div class="raffle-info">
      <div class="info-count">剩余抽奖次数：次</div>
      <div class="info-log">
        <div class="log-btn"></div>
      </div>
      <div class="info-blurb">
        <div class='rich-text'>
          1111111
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'raffle',
  data() {
    return {
      blocks: [
        {
          padding: '0.3rem',
          background: '#869cfa',
          imgs: [
            {
              src: require('@/assets/raffle/raffle-wheel.png'),   //图片url
              top: '0',     //图片距顶部距离
              width: '100%',  //图片宽
              height: '100%', //图片高
            }
          ],
        }
      ],
      prizes: [
        {background: '#fff', fonts: [{text: '多共呢个电动平板', top: '30%'}]},
        {background: '#fff1b9', fonts: [{text: '1', top: '30%'}]},
        {background: '#fff', fonts: [{text: '2', top: '30%'}]},
        {background: '#fff1b9', fonts: [{text: '3', top: '30%'}]},
        {background: '#fff', fonts: [{text: '4', top: '30%'}]},
        {background: '#fff1b9', fonts: [{text: '5', top: '30%'}]},
      ],
      buttons: [
        {radius: '38%', background: '#fff'},
        {
          radius: '35%',
          pointer: true,
          //抽奖按钮图
          imgs: [
            {
              src: require('@/assets/raffle/raffle-pointer.png'),   //图片url
              top: '-1.03rem',     //图片距顶部距离
              width: '100%',  //图片宽
              // height:'100%', //图片高
            }
          ],
        }
      ]
    }
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      // 调用抽奖组件的play方法开始游戏
      this.$refs.myLucky.play()
      // 模拟调用接口异步抽奖
      setTimeout(() => {
        // 假设后端返回的中奖索引是0
        const index = 5
        // 调用stop停止旋转并传递中奖索引
        this.$refs.myLucky.stop(index)
      }, 3000)
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize)
    },
  }
}
</script>

<style scoped lang='less'>
.yd-raffle {
  position: relative;
  overflow: hidden;
  width: 100%;

  .raffle-wheel {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 2.4rem;
    background-image: url("../assets/raffle/raffle-bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .raffle-info {
    position: relative;
    box-sizing: border-box;
    padding: 0.18rem 0.18rem 1rem 0.18rem;
    font-size: 0.28rem;

    .info-count {
      text-align: center;
      color: #fff;
    }

    .info-log {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 0.18rem;

      .log-btn {
        height: 0.68rem;
        width: 2rem;
        line-height: 0.68rem;
        color: #fff;
        padding: 0 0.3rem;
        background-image: url("../assets/raffle/raffle-log.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }

    .info-blurb {
      box-sizing: border-box;
      padding: 0.18rem;
    }
  }
}
</style>>
