<template>
  <div class='trends-detail'>
    <div class="yd-back" @click="clickBack"></div>
    <div class='body'>
      <div class="title">{{ news.title }}</div>
      <div class='time'>{{ news.createTime | convertTime }}</div>
      <div class='content rich-text' v-html="news.content">
      </div>
    </div>
  </div>
</template>

<script>
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {AuthUtil, LocalStorageUtil, StorageUtil} from "@/js/auth";
import {commonUtil} from "@/js/commonUtil";
import store from "@/store/store";

export default {
  name: 'trendsDetail',
  data() {
    return {
      activity: store.state.activity,
      news: ''
    }
  },
  created() {
    let trendsInfo = StorageUtil.getObject('trendsPage');
    this.getNews(trendsInfo.id)
  },
  mounted() {
    AuthUtil.weChatShareConfig();
  },
  methods: {
    clickBack() {
      let actId = this.activity.id;
      const parentPath = LocalStorageUtil.getString("parentPath");
      this.$router.push({
        path: parentPath === '/' ? '/activity/vote' : parentPath,
        query: {activityId: actId}
      });
    },
    getNews(id) {
      let url = api.activity.getNewsDetail.replace('{id}', id)
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.news = data;
        if (data.contentUrl != null && data.contentUrl != '') {
          this.$router.go(-1);
          window.location.href = data.contentUrl;
        } else {
          data.content = commonUtil.richTextFilter(data.content)
        }
      }, err => {
        this.$router.push('/error');
      })
    }
  }
}
</script>
<style scoped>
</style>
