import OSS from 'ali-oss'
import {api} from '../js/api'
import {ajaxUtil} from '../js/ajaxUtil'
import * as imageConversion from 'image-conversion'

var baseDir = 'public/user/'; //文件上传的目录

var UploadUtil = {
    upload: function (file, options, successCallback, failCallback) {
        //TODO 验证获取openid
        let requestData = {wxOpenId: ''};
        ajaxUtil.ajaxJson(api.oss.getEntryToken, requestData, 'POST').then(data => {
            let client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou,
                //后台返回了regionId: 如 cn-hangzhou, 所以这里拼接上oss-
                region: 'oss-' + data.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: data.accessKeyId,
                accessKeySecret: data.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: data.stsToken,
                // 填写Bucket名称。
                bucket: data.bucket
            });

            //上传文件
            let fileName = this.generateNewFileUri(file.name);
            imageConversion.compressAccurately(new Blob([file]), 300).then(img => {
                img = new File([img], file.name, {
                    type: img.type,
                    lastModified: Date.now()
                });
                client.multipartUpload(fileName, img, options).then((res) => {
                    if (successCallback != null) {
                        successCallback(res, fileName);
                    }
                }).catch(err => {
                    if (failCallback != null) {
                        failCallback(err);
                    } else {
                        this.$toast("上传失败");
                    }
                })
            }).catch(err => {
                if (failCallback != null) {
                    failCallback(err);
                } else {
                    this.$toast("上传失败");
                }
            })

        }, err => {
            if (failCallback != null) {
                failCallback(err);
            } else {
                this.$toast("上传失败");
            }
        })
    },

    generateNewFileUri: function (filename) {
        let suffix = this.get_suffix(filename);
        var randomString = new Date().getTime() + "";
        var newFileName = randomString + suffix;
        var split = randomString.split("");
        var newUri = baseDir + split[split.length - 2] + "/" + split[split.length - 1] + "/" + newFileName;
        return newUri
    },

    get_suffix: function (filename) {
        let suffix = '';
        if (filename == null) {
            return suffix;
        }
        var pos = filename.lastIndexOf('.');
        if (pos != -1) {
            suffix = filename.substring(pos)
        }
        return suffix;
    }
}

export {UploadUtil};
