import Vue from 'vue'
import App from './App.vue'
import './js/vueFilter'
import router from './router'
import store from './store/store'
import VueClipBoard from 'vue-clipboard2'
Vue.use(VueClipBoard)
import LuckDraw from 'vue-luck-draw'
Vue.use(LuckDraw)
//引入vant 组件
import {
    Button,
    CountDown,
    Dialog,
    Empty,
    Field,
    Icon,
    ImagePreview,
    List,
    NoticeBar,
    Overlay,
    Picker,
    Popup,
    Swipe,
    SwipeItem,
    Tab,
    Tabbar,
    TabbarItem,
    Tabs,
    Toast,
    Uploader
} from 'vant';
import {LocalStorageUtil} from "@/js/auth";
Vue.use(Swipe).use(Toast).use(SwipeItem).use(Tabbar).use(TabbarItem).use(NoticeBar).use(Icon).use(Tab).use(Popup)
    .use(Tabs).use(CountDown).use(Empty).use(List).use(Button).use(Dialog).use(Picker).use(Field).use(Uploader)
    .use(ImagePreview).use(Overlay);
Vue.config.productionTip = false

//初始化路由index
store.state.routeIndex = 0;
router.beforeEach((to, from, next) => {
    store.state.routeIndex = store.state.routeIndex + 1;
    store.setRoute(to.name);
    window.scroll(0, 0);
    LocalStorageUtil.storeString("parentPath", from.path);
    next();
    // if (to.path !== '/topic' && to.path !== '/error') {//若是act相关，则检查templateId(codeId)
    //     if (store.state.themes.templateID <= 0 || store.state.activity == null) {
    //         router.push("/error");
    //     } else {
    //         next();
    //     }
    // } else {
    //     next();
    // }

});

router.afterEach((to, from) => {
    //为各页面设置分享
    // AuthUtil.weChatShareConfig();
})

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
