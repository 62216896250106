/**
 * Created by Administrator on 2017/9/15.
 */
import Vue from 'vue';
import moment from 'moment'
import {config} from '../js/config'
import store from '@/store/store'

Vue.filter('upperCase', function (val) {
    if (val == null || val == '') {
        return val;
    } else {
        return val.toUpperCase();
    }
});

Vue.filter('convertDate', function (val) {
    if (val == null || val == '') {
        return ''
    } else {

        return moment(parseInt(val)).format('YYYY-MM-DD');
    }
});

Vue.filter('convertTime', function (val) {
    if (val == null || val == '') {
        return ''
    } else {
        return moment(parseInt(val)).format('YYYY-MM-DD HH:mm:ss');
    }
});

Vue.filter('convertActStatus', function (val, startTime, endTime) {
    if (val == null || val == '') {
        return ''
    } else {
        if (val.index != 1) {
            return val.desc;
        }

        if (startTime == null || endTime == null) {
            return '未知'
        }

        let now = moment().valueOf();
        if (startTime > now) {
            return '未开始';
        } else if (startTime < now && endTime > now) {
            return '进行中';
        } else if (endTime < now) {
            return '已结束'
        }
    }
});

Vue.filter('appendLocalImgUrl', function (val) {
    if (val == null || val == '') {
        return ''
    } else {
        let templateId = store.state.themes.templateID;
        let imgPathPreFix = '@/assets/images' + templateId + '/'
        return imgPathPreFix + val;
    }
});

Vue.filter('appendImgUrl', function (val) {
    if (!val) {
        return ''
    } else {
        return config.imgUrlHost + val;
    }
});
/**
 * 新过滤
 */
//oss 文件路径过滤拼接
Vue.filter('ossFileFilter', function (val) {
    if (val) {
        return config.imgUrlHost + val;
    }
    return ''
});

Vue.filter('ossAvatarFilter', function (val) {
    if (val) {
        return config.imgUrlHost + val;
    }
    return require('../assets/avatar-def.png')
});
Vue.filter('rankTopFilter', function (val) {
    switch (val) {
        case 1:
            return '第一名'
        case 2:
            return '第二名'
        case 3:
            return '第三名'
        default:
            return '第' + val + '名'
    }
});


