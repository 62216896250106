import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import blurb from '../views/blurb.vue'
import raffle from '../views/raffle.vue'
import entry from '../views/entry.vue'
import vote from '../views/vote.vue'
import rank from '../views/rank.vue'
import trends from '../views/trends.vue'
import trendsDetail from '../views/trendsDetail.vue'
import playerDetail from '../views/player.vue'
import topic from '../views/topic.vue'
import error from '../views/error.vue';
import wxAuthCallBack from '../views/wxAuthCallBack.vue';
import wxWork from '@/views/wxWork.vue';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'App',
        redirect: '/activity'
    },
    {
        path: '/activity',
        name: 'index',
        component: index,
        children: [{
            path: 'blurb',
            name: 'blurb',
            component: blurb,
        },
            {
                path: 'vote',
                name: 'vote',
                component: vote,
            },
            {
                path: 'rank',
                name: 'rank',
                component: rank,
            },
            {
                path: 'entry',
                name: 'entry',
                component: entry,
            }, {
                path: 'raffle',
                name: 'raffle',
                component: raffle,
            },
            {
                path: 'trends',
                name: 'trends',
                component: trends,
            }, {
                path: 'trendsDetail',
                name: 'trendsDetail',
                component: trendsDetail,
            }, {
                path: 'playerDetail',
                name: 'playerDetail',
                component: playerDetail,
            }]
    },
    {
        path: '/topic',
        name: 'topic',
        component: topic,
    },
    {
        path: '/error',
        name: 'error',
        component: error,
    },
    {
        path: '/wxAuthCallBack',
        name: 'wxAuthCallBack',
        component: wxAuthCallBack,
    },
    {
        path: '/wxWork',
        name: 'wxWork',
        component: wxWork,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
