<template>
  <div class='yd-blurb'>
    <tp-header></tp-header>
    <div :class="'body '+tab">
      <div class="pane">
        <div class="tab" v-if="isShow">
          <div class="intro" @click="bindTabChange('intro')">活动介绍</div>
          <div class="trends" @click="bindTabChange('trends')">活动动态</div>
        </div>
        <div v-else class="def"></div>
        <div class="content">
          <div class="intro">
            <div class="rich-text" v-if="introduce" v-html="introduce"></div>
            <van-empty v-else description="暂无介绍"/>
          </div>
          <div class="trends">
            <yd-trends-list></yd-trends-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from '../store/store.js'
import {AuthUtil, StorageUtil} from "@/js/auth";
import ydTrendsList from '../components/trends.vue'
import {commonUtil} from "@/js/commonUtil";

export default {
  name: 'blurb',
  data() {
    return {
      actId: 0,
      tab: 'intro',
      introduce: '',
      news: [],
      isShow: false,
    }
  },
  components: {ydTrendsList},
  created() {
    //初始化显示设置
    store.state.isShowBanner = true;
    let {introduce, settingShowIntroduce, id} = store.state.activity;
    this.introduce = commonUtil.richTextFilter(introduce)
    this.isShow = settingShowIntroduce.hasActivityNews;
    this.actId = id

  },
  mounted() {
    AuthUtil.weChatShareConfig();
  },
  methods: {
    bindTabChange(name) {
      this.tab = name
    },
  }
}
</script>
<style scoped lang='less'>
</style>
