<template>
  <div class="wx-work">
    <div class="tip-pane">
      <div class="tip-icon">
        <van-icon name="info"/>
      </div>
      <div class="tip-title">请在微信中打开链接</div>
      <div class="tip-url">{{ url }}</div>
    </div>
    <div class="copy-url">
      <button class='copy-btn' @click="bindJumpWxChange">
        复制链接并前往微信打开
      </button>
    </div>
  </div>
</template>

<script>
import {Toast} from 'vant'
import {commonUtil} from "@/js/commonUtil";

export default {
  name: 'wxWork',
  data() {
    return {
      url: ''
    }
  },
  created() {
    let params = commonUtil.getUrlParams();
    this.url = decodeURIComponent(params.actUrl)
  },
  methods: {
    bindJumpWxChange() {
      this.$copyText(this.url).then((e) => {
        Toast('复制成功');
        setTimeout(() => {
          window.location.href = 'weixin://';
        }, 500);
      }, (e) => {
        console.log(e);
      });
    }
  }
}
</script>
<style scoped lang='less'>
.wx-work {
  position: relative;
  width: 100%;
  height: 100%;
  .tip-pane{
    position: relative;
    box-sizing: border-box;
    padding: 0.3rem;
    top: 16%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .tip-icon{
      .van-icon{
        color: #4079fa;
        font-size: 1rem;
      }
    }
    .tip-title{
      font-size: 0.3rem;
      font-weight: bold;
      line-height: 0.6rem;
    }
    .tip-url{
      width: 100%;
      margin-top: 0.3rem;
      box-sizing: border-box;
      padding: 0.18rem;
      font-size: 0.24rem;
      background-color: #f0f0f0;
      color: #999999;
      line-height: 0.48rem;
      border-radius: 0.1rem;
    }
  }
  .copy-url{
    width: 100%;
    position: absolute;
    bottom: 1rem;
    display: flex;
    justify-content: center;
    .copy-btn{
      width: 4rem;
      font-size: 0.3rem;
      background-color: #4079fa;
      color: #fff;
      border: none;
      border-radius: 0.1rem;
      line-height: 0.8rem;
    }
  }
}
</style>
