<template>
  <div class="yd-ad-start">
    <div v-if="isAnime" class="anime">
      <img class="anime-img" :src="ad.startAnime|appendImgUrl" alt="">
      <div class="anime-bottom">
        <template v-if="animeProgress>99">
          <img class="anime-btn"
               :src="ad.startAnimeBtn|appendImgUrl"
               @click="bindSkipChange" alt="">
        </template>
        <template v-else>
          <div class="progress-num">{{ animeProgress }}</div>
          <div class="anime-progress">
            <div class="progress-finish" :style="{width:animeProgress+'%'}"></div>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="start">
      <img :src="ad.startAd|appendImgUrl" alt="">
      <div class="skip" @click="bindSkipChange">跳过 {{ startAdTime }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YdAdStart',
  data() {
    return {
      isAnime: false,
      animeProgress: 0,
      startAdTime: 3,
      adInterval: '',
    }
  },
  props: ['ad'],
  created() {
    let {startType, startTime} = this.ad;
    if (startTime) {
      this.startAdTime = startTime
    }
    if (startType === 2) {
      this.isAnime = true
      this.animeCountdown()
    } else {
      this.startCountdown()
    }
  },

  methods: {
    startCountdown() {
      let that = this
      let seconds = that.startAdTime; // 设置倒计时的起始时间（单位：秒）
      that.adInterval = setInterval(() => {
        if (seconds > 0) {
          this.startAdTime = seconds; // 将剩余时间展示到页面上
          seconds--;
        } else {
          if (that.adInterval) {
            clearInterval(that.adInterval); // 清除定时器
          }
          this.$emit('close')
        }
      }, 1000); // 每隔1秒更新一次倒计时显示值
    },

    animeCountdown() {
      let seconds = 0; // 设置倒计时的起始时间（单位：秒）
      let that = this
      that.adInterval = setInterval(() => {
        if (seconds < 100) {
          this.animeProgress = seconds; // 将剩余时间展示到页面上
          seconds += 4;
        } else {
          if (that.adInterval) {
            clearInterval(that.adInterval); // 清除定时器
          }
          this.animeProgress = 100
        }
      }, 100); // 每隔1秒更新一次倒计时显示值
    },

    bindSkipChange() {
      if (this.adInterval) {
        clearInterval(this.adInterval); // 清除定时器
      }
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang='less'>
.yd-ad-start {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;

  .start {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .skip {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      color: #fff;
      font-size: 0.23rem;
      line-height: 0.23rem;
      background-color: rgba(0, 0, 0, 0.4);
      box-sizing: border-box;
      padding: 0.13rem 0.2rem;
      border-radius: 0.1rem;
    }
  }

  .anime {
    position: relative;
    width: 100%;
    height: 100%;

    .anime-img {
      display: block;
      width: 100%;
      height: 100%;
    }

    .anime-bottom {
      position: absolute;
      width: 100%;
      bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      padding: 0 0.18rem;

      .anime-btn {
        width: 56%;
        height: auto;
        animation: anime-btn 2s infinite;
      }

      @keyframes anime-btn {
        0% {
          transform: scale(1);
        }

        50% {
          transform: scale(1.2);
        }

        100% {
          transform: scale(1);
        }
      }

      .progress-num {
        color: #fff;
        font-size: 0.52rem;
      }

      .anime-progress {
        width: 100%;
        box-sizing: border-box;
        padding: 0.04rem;
        height: 0.4rem;
        border: 0.04rem solid #fff;
        overflow: hidden;
        border-radius: 0.16rem;

        .progress-finish {
          box-sizing: border-box;
          border-radius: 0.16rem;
          height: 100%;
          width: 0;
          background-color: #fff;
        }
      }
    }


  }
}
</style>
