<template>
  <div class="yd-trends">
    <div class="body">
      <yd-trends-list></yd-trends-list>
    </div>
  </div>
</template>

<script>
import ydTrendsList from '../components/trends.vue'
import {AuthUtil} from "@/js/auth";
import store from "@/store/store";

export default {
  name: 'trends',
  data() {
    return {
      activity: store.state.activity,
      dataList: [{}, {}, {}]
    }
  },
  components: {
    ydTrendsList
  },
  mounted() {
    AuthUtil.weChatShareConfig();
  },
  methods: {}

}
</script>
<style scoped lang='less'>

</style>
