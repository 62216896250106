<template>
  <!--弹窗广告-->
  <van-popup v-model="show" class="yd-ad-eject" :close-on-click-overlay="false">
    <div class="eject-ad">
      <div class="title">投票成功</div>
      <div class="content">
        <img :src="eject | appendImgUrl" alt="" @click="bindAdLinkChange(link)">
      </div>
      <div class="close">
        <div class="close-btn" @click.stop="bindCloseChange">关闭</div>
      </div>
    </div>
  </van-popup>
</template>
<script>

export default {
  name: 'YdAdEject',
  data() {
    return {}
  },
  props: ['show', 'eject', 'link'],
  methods: {
    bindAdLinkChange(link) {
      if (link) {
        window.location.href = link;
      }
    },
    bindCloseChange() {
      this.$emit('close')
    },
  }
}
</script>
<style scoped lang='less'>
.yd-ad-eject {
  background-color: transparent !important;

  .eject-ad {
    width: 5.2rem;
    font-size: 0.32rem;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0.2rem;
    overflow: hidden;
    color: #333;

    .title {
      font-weight: bold;
      text-align: center;
      height: 1.2rem;
      line-height: 1.2rem;
    }

    .content {
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .close {
      height: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .close-btn {
        font-size: 0.26rem;
        text-align: center;
        width: 2rem;
        line-height: 0.5rem;
      }
    }
  }
}
</style>
