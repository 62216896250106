<template>
  <div id="ydNav" v-if="isShow">
    <div :class="['nav-list',active]">
      <div v-for="item in navList" :key="item.code"
           :class="['nav-item',item.code]" v-show="item.hasShow"
           @click="bindNavChange(item)">
        <div class="item-icon">
          <div class="icon">
            <img v-if="item.code ==='custom'&&item.uicon"
                 class="custom-icon" alt=""
                 :src="item.uicon | appendImgUrl">
          </div>
        </div>
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../store/store'
import {StorageUtil} from "@/js/auth";

export default {
  name: 'ydNav',
  data() {
    return {
      navList: [],
      isShow: true,
      active: 'vote',
      navOption: {
        '/activity/blurb': 'blurb',
        '/activity/vote': 'vote',
        '/activity/rank': 'rank',
        '/activity/trends': 'trends',
        '/activity/entry': 'entry',
      }
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        let code = this.navOption[route.path];
        if (code) {
          this.active = code
        }
      },
    }
  },

  created() {
    this.homeTypeFilter();
  },

  methods: {
    bindNavChange(item) {
      let {code, link} = item
      if (code === 'search') {
        store.state.searchStatus = true;
      } else if (code === 'custom') {
        if (link) {
          window.location.href = link;
        }
      } else {
        let currentPath = this.$router.currentRoute.path;
        let path = '/activity/' + code
        if (path !== currentPath) {
          let actId = store.state.activityId;
          this.$router.push({
            path: path,
            query: {activityId: actId, t: new Date().getTime()}
          });
        }
      }
    },
    homeTypeFilter() {
      let isJumpPlayer = StorageUtil.getString('isJumpPlayer');
      let currentPath = this.$router.currentRoute.path;
      let navList = store.state.activity.navList;
      this.navList = navList
      let navShowCount = 0
      let navHomeCode;
      for (let i = 0; i < navList.length; i++) {
        let {hasShow, hasHome, code} = navList[i];
        if (hasShow) {
          navShowCount += 1
          if (hasHome) {
            navHomeCode = code;
          }
        }
      }
      this.isShow = navShowCount > 1
      if (navHomeCode) {
        let path = '/activity/' + navHomeCode
        if (path === currentPath) {
          this.active = navHomeCode
        } else {
          let actId = store.state.activityId;
          if (isJumpPlayer) {
            let playerInfo = StorageUtil.getObject('playerPage');
            this.$router.push({
              path: '/activity/playerDetail',
              query: {activityId: actId, p: playerInfo.id + '_' + playerInfo.groupId, t: new Date().getTime()}
            });
          } else {
            this.$router.push({
              path: path,
              query: {activityId: actId, t: new Date().getTime()}
            });
          }
        }
      }
    }
  }
}
</script>
<style scoped lang='less'>
#ydNav {
  width: 100%;
  z-index: 999;

  .nav-list {
    width: 100%;
    display: flex;
    justify-content: space-around;

    .icon:empty {
      background-image: url("../assets/logo.png");
      background-size: 100% 100%;
    }

    .custom-icon {
      display: block;
      width: 100%;
      height: 100%;
    }
  }


}

</style>
