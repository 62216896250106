<template>
  <div class="yd-trends-list">
    <div v-if="trendsList&&trendsList.length">
      <div class='item' v-for="item in trendsList" :key="item.id" @click='bindTrendsChange(item)'>
        <div class="title">
          <p>{{ item.title }}</p>
          <van-icon name="arrow" size="0.26rem"/>
        </div>
        <div class="cover" v-if="item.imageUrl">
          <img :src="item.imageUrl | ossFileFilter" alt="">
        </div>
        <div class="time">{{ item.createTime|convertTime }}</div>
      </div>
    </div>
    <van-empty v-else description="暂无动态"/>
  </div>
</template>

<script>
import store from '../store/store.js'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {StorageUtil} from "@/js/auth";

export default {
  name: 'ydTrendsList',
  data() {
    return {
      activity: store.state.activity,
      trendsList: []
    }
  },
  created() {
    let actId = store.state.activity.id
    this.getTrendsList(actId)
  },
  methods: {
    getTrendsList(actId) {
      let url = api.activity.getNews.replace("{activityId}", actId).replace('{showType}', 1)
          .replace('{pageNo}', 1).replace('{pageCount}', 1000);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        this.trendsList = data.resultList;
      })
    },

    bindTrendsChange(trends) {
      if (trends.contentUrl) {
        window.location.href = trends.contentUrl;
      } else {
        //跳转到动态详情页面
        StorageUtil.storeObject('trendsPage', {id: trends.id})
        this.$router.push({path: 'trendsDetail', query: {activityId: this.activity.id, id: trends.id}})
      }
    }
  }
}
</script>
<style scoped>

</style>
