<template>
  <div class="yd-entry">
    <div class="entry-pane">
      <div class="entry-title">
        <h2></h2>
      </div>
      <div class="entry-group">
        <div class="entry-time" v-if="settingEntry.hasTime">
          <div class="time-title">
            <h2>{{ settingEntry.title ? settingEntry.title : '报名时间' }}</h2>
          </div>
          <div class="time-row">
            <div class="row-label">
              <van-icon name='clock-o'/>
              {{ settingEntry.startTimeTitle ? settingEntry.startTimeTitle : '报名时间' }}：
            </div>
            <div class="row-content">
              {{ settingEntry.startTime | convertTime }}
            </div>
          </div>
          <div class="time-row">
            <div class="row-label">
              <van-icon name='clock-o'/>
              {{ settingEntry.endTimeTitle ? settingEntry.endTimeTitle : '截止时间' }}：
            </div>
            <div class="row-content">
              {{ settingEntry.endTime | convertTime }}
            </div>
          </div>
        </div>
        <div class="entry-form">
          <div class="form-title">
            <h2>{{ settingEntry.title ? settingEntry.title : '报名时间' }}</h2>
          </div>
          <div v-for="(value,index) in customForm" :key="index">
            <template v-if="value[0]==='group'">
              <div class="form-row" v-if="groups.length">
                <div class="row-pane">
                  <div class="row-label">
                    {{ settingEntry.groupTitle ? settingEntry.groupTitle : value[1] }}
                  </div>
                  <div class="row-content">
                    <div class="content-group" @click="showGroupPicker=true">
                      <div v-if="value[5]">{{ groupFilter(value[5]) }}</div>
                      <div v-else class="content-tips">请选择分组（必选项）</div>
                      <div class="sel-group-btn">
                        <van-icon name="arrow"/>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </template>
            <template v-else-if="value[0]==='name'">
              <div class="form-row">
                <div class="row-pane">
                  <div class="row-label">{{ value[1] }}</div>
                  <div class="row-content">
                    <van-field class="content-text"
                               v-model="value[5]"
                               :placeholder="'请输入'+value[1]+(value[3]===1?'(必填项)':'')"/>
                    <div class="content-tips" v-if="settingEntry.playerNameTips">
                      {{ settingEntry.playerNameTips }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="value[0]==='blurb'">
              <div class="form-row" v-if="value[3]!==2">
                <div class="row-pane">
                  <div class="row-label">{{ value[1] }}</div>
                  <div class="row-content">
                    <van-field class="content-text"
                               rows="3"
                               autosize
                               type="textarea"
                               v-model="value[5]"
                               :placeholder="'请输入'+value[1]+(value[3]===1?'(必填项)':'')"/>
                    <div class="content-tips" v-if="settingEntry.playerBlurbTips">
                      {{ settingEntry.playerBlurbTips }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="value[0]==='custom'">
              <div class="form-row" v-if="value[3]!==2">
                <div class="row-pane">
                  <div class="row-label">{{ value[1] }}</div>
                  <div class="row-content">
                    <div v-if="value[2]===0">
                      <van-field class="content-text"
                                 v-model="value[5]"
                                 :placeholder="'请输入'+value[1]+(value[3]===1?'(必填项)':'')"/>
                    </div>
                    <div v-else-if="value[2]===1">
                      <van-field class="content-text"
                                 rows="3"
                                 autosize
                                 type="textarea"
                                 v-model="value[5]"
                                 :placeholder="'请输入'+value[1]+(value[3]===1?'(必填项)':'')"/>
                    </div>
                    <div v-else-if="value[2]===2">
                      <van-field class="content-text"
                                 type="number"
                                 v-model="value[5]"
                                 :placeholder="'请输入'+value[1]+(value[3]===1?'(必填项)':'')"/>
                    </div>
                    <div v-else-if="value[2]===3"></div>
                    <div v-else-if="value[2]===4"></div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="value[0]==='video'&&settingEntry.hasVideo&&settingEntry.videoType===1">
              <div class="form-row" v-if="value[3]!==2">
                <div class="row-pane" style="display: block;">
                  <div class="row-label">{{ value[1] }}</div>
                  <div class="row-content">
                    <!--代码-->
                    <van-field class="content-text"
                               rows="3"
                               autosize
                               type="textarea"
                               v-model="value[5]"
                               :placeholder="'请输入视频代码'+(value[3]===1?'(必填项)':'')"/>
                    <!--本地文件-->
                    <!--                    <template v-if="settingEntry.videoType===2">-->
                    <!--                      <van-uploader accept="video/*" multiple-->
                    <!--                                    :max-count="1"-->
                    <!--                                    v-model="value[5]"-->
                    <!--                                    :before-read="beforeVideoUpload"-->
                    <!--                                    :after-read="afterVideoUpload"-->
                    <!--                      />-->
                    <!--                    </template>-->
                    <div class="content-tips" v-if="settingEntry.videoTips">
                      {{ settingEntry.videoTips }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="value[0]==='image'&&settingEntry.hasImage">
              <div class="form-row" v-if="value[3]!==2">
                <div class="row-pane" style="display: block;">
                  <div class="row-label">{{ settingEntry.imageTitle ? settingEntry.imageTitle : value[1] }}</div>
                  <div class="row-content">
                    <van-uploader accept="image/*" multiple
                                  :max-count="settingEntry.imageMaxNum"
                                  v-model="value[5]"
                                  :before-read="beforeImageUpload"
                                  :after-read="afterImageUpload"
                    />
                    <div class="content-tips" v-if="settingEntry.imageTips">
                      {{ settingEntry.imageTips }}
                    </div>
                    <div class="content-tips" v-else>
                      图片数量{{ settingEntry.imageMinNum }}~{{ settingEntry.imageMaxNum }}张,
                      体积小于10M
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="entry-btn">
          <button class='btn-submit' @click='bindEntryChange'>
            {{ settingEntry.btnName ? settingEntry.btnName : '报名' }}
          </button>
        </div>
      </div>
    </div>
    <van-popup v-model="showGroupPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="groups"
          value-key="name"
          @confirm="bindSelGroupChange"
          @cancel="showGroupPicker = false"/>
    </van-popup>
    <AuthWxDialog :showDialog="showWxDialog" @onCancel="showWxDialog=false"></AuthWxDialog>
  </div>
</template>

<script>
import store from '../store/store.js'
import {api} from '@/js/api'
import {ajaxUtil} from '@/js/ajaxUtil'
import {AuthUtil} from "@/js/auth";
import {UploadUtil} from "@/js/uploadUtil";
import AuthWxDialog from "@/components/authWxDialog.vue";

export default {
  name: 'entry',
  components: {AuthWxDialog},
  data() {
    return {
      actId: '',
      settingEntry: {},
      groups: [],
      showGroupPicker: false,
      selGroupId: null,
      customForm: [],
      showWxDialog: false,
    }
  },
  computed: {},
  created() {
    store.state.isShowBanner = true;
    let {id, settingEntry} = store.state.activity;
    let customForm = JSON.parse(settingEntry.customForm);
    for (let i = 0; i < customForm.length; i++) {
      let custom = customForm[i];
      if (custom[0] === 'image') {
        custom[5] = []
      }
    }
    this.customForm = customForm
    //初始化分组信息
    this.actId = id
    this.settingEntry = settingEntry
    this.getGroups();
  },
  mounted() {
    AuthUtil.weChatShareConfig();
  },
  methods: {
    getGroups() {
      this.groups = [];
      let url = api.activity.getGroups.replace('{activityId}', this.actId);
      ajaxUtil.ajaxJson(url, null, 'GET').then(data => {
        if (data && data.length) {
          this.groups = data
        }
      })
    },
    groupFilter(val) {
      let groups = this.groups;
      for (let i = 0; i < groups.length; i++) {
        let {id, name} = groups[i];
        if (val === id) {
          return name
        }
      }
      return ''
    },
    bindSelGroupChange(e) {
      let customForm = this.customForm;
      for (let i = 0; i < customForm.length; i++) {
        let custom = customForm[i];
        if (custom[0] === 'group') {
          custom[5] = e.id
          break
        }
      }
      this.showGroupPicker = false
    },
    beforeImageUpload(file, detail) {
      let {type, size} = file
      if (type !== 'image/jpeg' && type !== 'image/jpg' && type !== 'image/png') {
        this.$toast("仅支持jpeg、jpg、png");
        return false;
      }
      let imageSize = this.settingEntry.imageSize;
      if (size > 10 * 1024 * 1024) {
        this.$toast("文件大小不得超过" + imageSize + "KB");
        return false;
      }
      return true;
    },
    afterImageUpload(file, detail) {
      file.status = 'uploading';
      file.message = '上传中...';
      //上传文件
      UploadUtil.upload(file.file, {
        progress: (p, checkpoint) => {
          // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
          this.percentage = p * 100;
        },
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        file.status = 'success';
        file.url = res.name;
        this.$toast.success("上传成功");
      }, (err) => {//失败回调
        //错误提示
        file.status = 'failed';
        file.message = '上传失败';
      });
    },
    beforeVideoUpload(file, detail) {
      let {type, size} = file
      if (type !== 'image/mp4') {
        this.$toast("仅支持mp4");
        return false;
      }
      let videoSize = this.settingEntry.videoSize;
      if (size > videoSize * 1024 * 1024) {
        this.$toast("文件大小不得超过" + videoSize + "M");
        return false;
      }
      return true;
    },
    afterVideoUpload(file, detail) {
      file.status = 'uploading';
      file.message = '上传中...';
      //上传文件
      UploadUtil.upload(file.file, {
        progress: (p, checkpoint) => {
          // checkpoint参数用于记录上传进度，断点续传上传时将记录的checkpoint参数传入即可。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
          this.percentage = p * 100;
        },
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
      }, (res) => {//成功回调
        file.status = 'success';
        file.url = res.name;
        this.$toast.success("上传成功");
      }, (err) => {//失败回调
        //错误提示
        file.status = 'failed';
        file.message = '上传失败';
      });
    },
    bindEntryChange() {
      // this.submitForm();
      // 微信授权登录
      let that = this;
      AuthUtil.wechatAuthLogin(true, isAuthOk => {
        if (isAuthOk) {
          that.submitForm();
        } else {
          that.showWxDialog = true;
        }
      })
    },
    submitForm() {
      let {hasVideo, hasVideoMust, videoType, hasImage, imageMinNum, imageMaxNum} = this.settingEntry;
      let user = store.state.user;
      let customForm = JSON.parse(JSON.stringify(this.customForm));
      let name, blurb
      for (let i = 0; i < customForm.length; i++) {
        let item = customForm[i];
        if (item[3] === 1 && !item[5]) {
          this.$toast("请将必填项补充完整");
          return false;
        }
        if (item[0] === 'name') {
          name = item[5]
        } else if (item[0] === 'blurb') {
          blurb = item[5]
        } else if (item[0] === 'group' && this.groups.length === 1) {
          item[5] = this.groups[0].id
        } else if (item[0] === 'video') {
          if (!item[5] || !item[5].length) {
            if (hasVideoMust) {
              this.$toast("请将补充报名视频");
              return false;
            } else {
              item[5] = ''
            }
          }
          if (videoType === 2 && item[5].length) {
            item[5] = item[5][0].url
          }
        } else if (item[0] === 'image') {
          const imageLength = item[5] ? item[5].length : 0;
          if (imageLength < imageMinNum || imageLength > imageMaxNum) {
            this.$toast("图片数量不符合要求");
            return false;
          }
          if (imageLength > 0) {
            let imageContent = [];
            for (let j = 0; j < imageLength; j++) {
              imageContent.push(item[5][j].url)
            }
            item[5] = imageContent.join(',')
          } else {
            item[5] = ''
          }
        }
      }
      let data = {
        activityId: this.actId,
        wxId: user.wxOpenId,
        name: name,
        blurb: blurb,
        customForm: JSON.stringify(customForm),
      };
      let that = this;
      ajaxUtil.ajaxJson(api.player.entry, data, 'POST').then(res => {
        that.$toast.success("报名成功");
        this.$router.push({path: 'vote'})
      }, err => {
        let errResponse = err.response;
        let errData = errResponse == null ? null : errResponse.data;
        let customError = errData == null ? null : errData.data;
        if (customError == null) {
          that.$toast('报名失败！')
        } else {
          that.$toast(customError.message);
        }
      })
    },
  }
}
</script>
<style scoped lang='less'>
.yd-entry {
  .entry-time {
    .time-row {
      display: flex;

      .row-label {
        box-sizing: border-box;

        .van-icon {
          box-sizing: border-box;
        }
      }

      .row-content {
        margin-left: -0.1rem;
        flex: 1;
        box-sizing: border-box;
      }
    }
  }

  .entry-form {
    .form-row {
      .row-label {
        box-sizing: border-box;
      }

      .row-content {
        flex: 1;
        box-sizing: border-box;

        .content-text {
          width: 100%;
          border: none;
          padding: 0;
          line-height: 0.48rem;
        }


      }
    }
  }

  .entry-btn {

  }
}
</style>
