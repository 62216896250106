<template>
  <div class='yd-poster-cover'>
    <van-popup v-model="isShow">
      <div class="poster-preview">
        <van-swipe indicator-color="#fff">
          <van-swipe-item v-for="(item, index) in posterList" :key="index">
            <img :src="item" alt="">
          </van-swipe-item>
        </van-swipe>
        <div class="preview-tips">左右滑动可切换,长按图片可保存</div>
        <div class="preview-close">
          <div class='close-btn' @click='close'></div>
        </div>
      </div>
    </van-popup>
    <div class="poster-code">
      <div class="poster-style code1" :style="bgColorStyle">
        <div class="header">
          <img src="@/assets/poster/p1-1.png" alt="">
          <div class="act-name" :style="colorStyle">{{ activity.name }}</div>
        </div>
        <div class="content">
          <img src="@/assets/poster/p1-2.png" alt="">
          <div class="player-info">
            <img v-if="player.imageUrl" :src="player.imageUrl|appendImgUrl" alt="" crossorigin="anonymous">
            <img v-else src="@/assets/avatar-def.png" alt="">
            <div class="player-num">
              <div class="line" :style="bgColorStyle"></div>
              <div class="num">{{ player.num }}号</div>
              <div class="line" :style="bgColorStyle"></div>
            </div>
            <div class="player-name">
              {{ playerNameFilter(player.name) }}
            </div>
          </div>
          <div class="player-tip">
            <img src="@/assets/poster/p1-3.png" alt="">
            <div class="tip-pane">
              <div>
                <div class="tip1">长按识别二维码</div>
                <div class="tip2" :style="bgColorStyle">帮我投票</div>
              </div>
              <div class="code" :style="borderStyle">
                <img :src="qrCodeSrc" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="foot">
          <img src="@/assets/poster/p1-4.png" alt="">
        </div>
      </div>
      <div class="poster-style code2" :style="bgColorStyle">
        <div class="header">
          <img src="@/assets/poster/p2-1.png" alt="">
        </div>
        <div class="content">
          <div class="player-info">
            <div class="player-avatar">
              <img v-if="player.imageUrl" :src="player.imageUrl|appendImgUrl" alt="" crossorigin="anonymous">
              <img v-else src="@/assets/avatar-def.png" alt="">
            </div>
            <div class="player-num">
              <div class="num">{{ player.num }}号</div>
            </div>
            <div class="player-name">
              {{ playerNameFilter(player.name) }}
            </div>
            <div class="act-banner">
              <img v-if="activity.settingShufflingImages[0]"
                   :src="activity.settingShufflingImages[0].shufflingImageUrl | appendImgUrl" alt=""
                   crossorigin="anonymous">
              <img v-else src="@/assets/logo.png" alt="">
            </div>
            <div class="act-name" :style="colorStyle">{{ activity.name }}</div>
          </div>
          <div class="player-tip">
            <img src="@/assets/poster/p1-3.png" alt="">
            <div class="tip-pane">
              <div>
                <div class="tip1">长按识别二维码</div>
                <div class="tip2" :style="bgColorStyle">帮我投票</div>
              </div>
              <div class="code" :style="borderStyle">
                <img :src="qrCodeSrc" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="poster-style code3" :style="bgColorStyle">
        <div class="background">
          <img src="@/assets/poster/p3-1.png" alt="">
        </div>
        <div class="content">
          <div class="act-info">
            <div class="act-name" :style="colorStyle">{{ activity.name }}</div>
            <div class="act-cover">
              <div class="player-avatar">
                <img v-if="player.imageUrl" :src="player.imageUrl|appendImgUrl" alt="" crossorigin="anonymous">
                <img v-else src="@/assets/avatar-def.png" alt="">
              </div>
              <div class="tip-pane">
                <div class="code" :style="borderStyle">
                  <img :src="qrCodeSrc" alt="">
                </div>
                <div class="tip1">
                  长按识别二维码<br>
                  帮我投票,谢谢
                </div>
              </div>
            </div>
          </div>
          <div class="player-info">
            <div class="player-name">
              名称：{{ playerNameFilter(player.name) }}
            </div>
            <div class="player-num">
              编号：{{ player.num }}号
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/store/store";
import Canvas2Image from "@/js/Canvas2Image";
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'

export default {
  name: 'ydPoster',
  data() {
    return {
      isShow: false,
      activity: store.state.activity,
      finishCount: 0,
      playerCover: '',
      actBanner: '',
      qrCodeSrc: '',
      posterList: [],
      bgColorStyle: '',
      borderStyle: '',
      colorStyle: '',
    }
  },
  props: ['show', 'player'],
  watch: {
    show: function (newVal, oldVal) {
      if (newVal) {
        this.posterList = []
        this.playerCover = ''
        this.actBanner = ''
        this.initPoster()
      }
    }
  },
  methods: {
    initPoster() {
      //初始化海报颜色
      let {settingStyle, h5Url} = this.activity;
      if (settingStyle.backgroundColor) {
        this.bgColorStyle = {backgroundColor: settingStyle.backgroundColor}
        this.colorStyle = {color: settingStyle.backgroundColor}
        this.borderStyle = {border: settingStyle.backgroundColor + ' solid 1px'}
      }
      let {id, groupId} = this.player;
      let playerUrl = h5Url + '&p=' + id + '_' + groupId
      let opts = {
        errorCorrectionLevel: "L",//容错级别
        type: "image/png",//生成的二维码类型
        quality: 0.8,//二维码质量
        margin: 3,//二维码留白边距
        width: 120,//宽
        height: 120,//高
        text: playerUrl,//二维码内容
        color: {
          dark: "#000",//前景色
          light: "#fff"//背景色
        }
      };
      QRCode.toDataURL(playerUrl, opts, (err, url) => {
        if (err) throw err
        //将生成的二维码路径复制给data的QRImgUrl
        this.qrCodeSrc = url
        this.$nextTick(() => {
          this.producePoster()
        })
      })
    },
    playerNameFilter(name) {
      if (name) {
        return name.replace(/<[^>]*>/g, '')
      }
      return ''
    },
    producePoster() {
      let elementList = document.getElementsByClassName('poster-style')
      let elementSize = elementList.length;
      this.$toast.loading({
        message: '生成海报中...',
        forbidClick: true,
        duration: 0,
      });
      for (let i = 0; i < elementSize; i++) {
        let item = elementList[i];
        this.onCanvas(item, i === elementSize - 1)
      }
    },
    onCanvas(item, isEnd) {
      let width = item.offsetWidth;
      let height = item.offsetHeight;
      let canvas = document.createElement("canvas");
      let scale = 2;
      canvas.width = width * scale;
      canvas.height = height * scale;
      let opts = {
        scale: scale,
        canvas: canvas,
        dpi: 300,
        width: width,
        height: height,
        backgroundColor: null,
        allowTaint: false,
        useCORS: true,//允许加载跨域的图片
        tainttest: true, //检测每张图片都已经加载完成
      }
      let that = this
      html2canvas(item, opts).then((canvas) => {
        let img = Canvas2Image.convertToPNG(canvas, canvas.width, canvas.height);
        this.posterList.push(img.src);
        if (isEnd) {
          that.isShow = true;
          that.$toast.clear();
        }
      })
    },
    close() {
      this.isShow = false
      this.$emit('close')
    },
  }
}
</script>
<style scoped lang='less'>
.yd-poster-cover {
  box-sizing: border-box;

  .van-popup {
    background: transparent;
  }


  .poster-preview {
    width: 5.6rem;

    .van-swipe-item {
      padding-bottom: 0.2rem;
      box-sizing: border-box;
      height: 100%;
      text-align: center;
    }

    img {
      overflow: hidden;
      width: 90%;
      height: 100%;
      -o-object-fit: contain;
      object-fit: contain;
    }

    .preview-tips {
      text-align: center;
      color: #d7d7d7;
      font-size: 0.24rem;
      font-weight: bold;
    }

    .preview-close {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      margin-top: 0.3rem;

      .close-btn {
        width: 0.6rem;
        height: 0.6rem;
        background: url('../assets/close-def.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }

  .poster-code {
    position: fixed;
    top: 0;
    left: -10rem;
    z-index: -99;

    .poster-style {
      width: 5.08rem;
      height: 9.03rem;
      position: relative;
      top: 0;
      left: 0;
      color: #fff;
    }
  }

  .code1 {
    .header {
      position: relative;

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      .act-name {
        font-size: 0.23rem;
        width: 100%;
        position: absolute;
        bottom: 0.18rem;
        text-align: center;
      }
    }

    .content {
      padding: 0.18rem;
      box-sizing: border-box;

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      .player-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        box-sizing: border-box;
        margin: 0 0.11rem;
        padding: 0.5rem 0;
        color: #333;

        img {
          width: 2.2rem;
          height: 2.2rem;
        }

        .player-num {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 0.18rem 0;

          .line {
            width: 0.5rem;
            height: 1px;
          }

          .num {
            font-weight: bold;
            padding: 0 0.18rem;
            text-align: center;
          }
        }

        .player-name {
          font-weight: bold;
        }
      }

      .player-tip {
        position: relative;
        box-sizing: border-box;
        padding: 0 0.11rem;

        .tip-pane {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 0 0.5rem;

          .tip1 {
            color: #333;
            margin-bottom: 0.1rem;
          }

          .tip2 {
            line-height: 0.34rem;
            text-align: center;
            border-radius: 0.16rem;
            width: 1.3rem;
          }

          .code {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 0.05rem;
          }
        }
      }
    }

    .foot {
      margin-top: 0.1rem;
      display: flex;
      justify-content: center;

      img {
        width: 3.6rem;
      }
    }
  }

  .code2 {
    .header {
      position: relative;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    .content {
      padding: 0.6rem 0.18rem 0.18rem 0.18rem;
      box-sizing: border-box;

      .player-info {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        box-sizing: border-box;
        margin: 0 0.11rem;
        padding: 0.18rem 0;
        color: #333;

        .player-avatar {
          position: absolute;
          overflow: hidden;
          top: -0.5rem;
          border-radius: 0.5rem;

          img {
            display: block;
            width: 1rem;
            height: 1rem;
          }
        }

        .player-num {
          width: 100%;

          .num {
            font-weight: bold;
            padding: 0 0.18rem;
          }
        }

        .player-name {
          font-weight: bold;
        }

        .act-banner {
          width: 100%;
          box-sizing: border-box;
          padding: 0.12rem;

          img {
            border-radius: 0.1rem;
            display: block;
            width: 100%;
            object-fit: cover;
            height: 3.6rem;
          }
        }

        .act-name {
          font-size: 0.23rem;
          text-align: center;
        }
      }

      .player-tip {
        position: relative;
        box-sizing: border-box;
        padding: 0 0.11rem;

        img {
          width: 100%;
          display: block;
        }

        .tip-pane {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 0 0.5rem;

          .tip1 {
            color: #333;
            margin-bottom: 0.1rem;
          }

          .tip2 {
            line-height: 0.34rem;
            text-align: center;
            border-radius: 0.16rem;
            width: 1.3rem;
          }

          .code {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 0.05rem;
          }
        }
      }
    }
  }

  .code3 {
    .background {
      position: absolute;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .content {
      padding: 0.6rem 0.18rem 0.18rem 0.18rem;
      box-sizing: border-box;

      .act-info {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        box-sizing: border-box;
        margin: 1.6rem 0.11rem 0 0.11rem;
        color: #333;

        .act-name {
          font-size: 0.23rem;
          text-align: center;
        }

        .act-cover {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          box-sizing: border-box;
          padding: 0.18rem;

          .player-avatar {
            img {
              display: block;
              width: 2.2rem;
              height: 2.2rem;
            }
          }

          .tip-pane {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;

            .tip1 {
              color: #333;
              font-size: 0.2rem;
              text-align: center;
            }

            .code {
              width: 1.2rem;
              height: 1.2rem;
              border-radius: 0.05rem;

              img {
                width: 100%;
                height: 100%;
                display: block;
              }
            }
          }
        }
      }

      .player-info {
        margin-top: 1.8rem;
        font-weight: bold;
        width: 100%;
        box-sizing: border-box;
        padding: 0 0.3rem;

        .player-name {
          padding: 0.18rem;
          border-bottom: 1px solid #fff;
        }

        .player-num {
          padding: 0.18rem;
          border-bottom: 1px solid #fff;
        }
      }
    }
  }
}
</style>
