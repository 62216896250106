/**
 * Created by Administrator on 2021/12/27.
 */

import QRCode from 'qrcodejs2';
import {LocalStorageUtil} from "@/js/auth";

const commonUtil = {

    copyObject: function (source) {
        return JSON.parse(JSON.stringify(source));
    },

    //获取url中的参数，以对象的形式返回
    getUrlParams: function () {
        var args = new Object();
        var query = location.search.substring(1);//获取查询串
        var pairs = query.split("&");//在逗号处断开
        for (var i = 0; i < pairs.length; i++) {
            var pos = pairs[i].indexOf('=');//查找name=value
            if (pos == -1) {//如果没有找到就跳过
                continue;
            }
            var argname = pairs[i].substring(0, pos);//提取name
            var value = pairs[i].substring(pos + 1);//提取value
            args[argname] = value;//存为属性
        }
        return args;//返回对象
    },

    //往url中添加参数，若参数存在则不做任何操作
    addUrlParams: function (url, paramName, paramVal) {
        if (url == null || url == '') {
            return ''
        }

        if (url.indexOf(paramName) > -1) {//如果参数已存在，则直接返回
            return url;
        } else {
            var paramStr = paramName + '=' + paramVal;
            var idx = url.indexOf('?');
            if (idx < 0) {
                url += '?';
            } else if (idx >= 0 && idx != url.length - 1) {
                url += '&';
            }
            url = url + paramStr;
        }
        return url;
    },

    //根据数组中元素的id值定位元素在数组中的index，注：只针对数组中的对象元素有“id”属性有效
    findIndexFromArrayById: function (array, id) {
        let result = -1;
        if (array == null || id == null) {
            return result;
        }
        for (var i in array) {
            if (id == array[i].id) {
                result = i;
                break;
            }
        }
        return result;
    },
    /**
     * 是否微信浏览器打开
     * @returns {number} 返回0：非微内置信浏览器; 1:在微信PC端上打开内置浏览器; 2:非微信PC端上打开内置浏览器(手机微信)；3：微信开发者工具打开
     */
    isWeChat: function () {
        let userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('micromessenger') !== -1) {
            //微信内置浏览器打开
            let system = {
                win: false,
                mac: false,
                //如果是微信开发者工具打开，则可以直接调用授权
                wechatDevTools: false
            };
            //检测平台
            let p = navigator.platform;
            system.win = p.indexOf("Win") === 0;
            system.mac = p.indexOf("Mac") === 0;
            system.wechatDevTools = userAgent.indexOf("wechatdevtools") !== -1;
            if ((system.win || system.mac) && !system.wechatDevTools) {
                //在微信PC端上打开内置浏览器
                return 1;
            } else if (system.wechatDevTools) {
                //开发者工具打开
                return 3;
            } else {
                //非微信PC端上打开内置浏览器(手机微信)
                return 2;
            }
        } else {
            //非微信浏览器打开
            return 0;
        }
    },
    noLoginUser: function () {
        let user = LocalStorageUtil.getObject('wxAuthVoteUserInfo');
        return (user == null || Object.keys(user).length === 0 || user.token === '');
    },
    createQrCode: function (el, content, width, height) {
        return new QRCode(el, {
            text: content, // 需要转换为二维码的内容
            width: width,
            height: height,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
    },

    urlToBase64(url, callback) {
        let Img = new Image(),
            dataURL = '';
        Img.src = url + "?v=" + Math.random();
        Img.setAttribute("crossOrigin", 'Anonymous')
        Img.onload = function () {
            let canvas = document.createElement("canvas"),
                width = Img.width,
                height = Img.height;
            canvas.width = width;
            canvas.height = height;
            canvas.getContext("2d").drawImage(Img, 0, 0, width, height);
            dataURL = canvas.toDataURL('image/png');
            callback ? callback(dataURL) : null;
        };
    },

    generateUuid: function () {//生成UUId
        var s = [];
        var hexDigits = "0123456789abcdef";
        for (var i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";

        var uuid = s.join("");
        return uuid;
    },
    /**
     * ==================================================
     */
    //根据数组中元素的id值是否存在，注：只针对数组中的对象元素有“id”属性有效
    existArrayById: function (array, id) {
        if (!array || !id) {
            return false;
        }
        for (const i in array) {
            if (id === array[i].id) {
                return true
            }
        }
        return false;
    },
    richTextFilter: function (val) {
        if (val) {
            let number = document.documentElement.clientWidth / 6.4;
            // 匹配所有的px值
            const reg = /(\d+)px/g;
            // 将匹配到的px值转换成rem
            return val.replace(reg,
                ($0, $1) => `${parseFloat($1) / number}rem`
            );
        }
        return ''
    },
}

export {commonUtil};
