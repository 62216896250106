<template>
  <div class="yd-copyright">
    <div v-if="index==1" class="def" :style="{color:copyright.copyrightColor}">
      ——— 元点评选 提供技术支持 ———
      <div v-if="copyright.hasCreateAct" class="create-act" @click="bindCreateChange">
        <span>我也要创建活动</span>
      </div>
    </div>
    <div v-if="index==3" class="custom" @click="bindCustomChange">
      {{ text }}
    </div>
  </div>
</template>

<script>
import {config} from "@/js/config";

export default {
  name: 'YdCopyright',
  data() {
    return {
      index: 1,
      text: '',
      jumpUrl: '',
      websiteUrl: ''
    }
  },
  props: ['copyright'],
  created() {
    let {copyrightCustomName, copyrightCustomUrl, copyrightStatus, copyrightDefUrl} = this.copyright
    this.index = copyrightStatus.index
    this.text = copyrightCustomName
    this.jumpUrl = copyrightCustomUrl
    this.websiteUrl = copyrightDefUrl
  },

  methods: {
    bindCustomChange() {
      if (this.jumpUrl) {
        window.location.href = this.jumpUrl
      }
    },
    bindCreateChange() {
      let websiteUrl = this.websiteUrl;
      window.location.href = websiteUrl ? websiteUrl : config.websiteUrl
    }
  }
}
</script>
<style scoped lang='less'>
.yd-copyright {
  div {
    text-align: center;
    padding: 1rem;
  }

  .def {
    display: flex;
    flex-direction: column;
    align-items: center;

    .create-act {
      background: rgba(255, 255, 255, 0.36);
      border-radius: 0.08rem;
      padding: 0.1rem 0.2rem;
      margin: 0.2rem 0;
    }
  }
}
</style>
