const api = {
    activity: {
        get: '/api/activity/get?activityId={activityId}',
        getActivityStatics :'/api/activity/getActivityStatics?activityId={activityId}',
        getNews:'/api/activitynews/query?activityId={activityId}&showType={showType}&pageNo={pageNo}&pageCount={pageCount}',
        getNewsDetail :'/api/activitynews/{id}',
        getGroups: '/api/player/group/list?activityId={activityId}',
        getPlayers: '/api/player/list?activityId={activityId}&sortType={sortType}&groupId={groupId}&showType={showType}&filterType={filterType}&pageNo={pageNo}&pageCount={pageCount}&searchKey={searchKey}',
        getPlayerRanking:'/api/player/getRankingList?activityId={activityId}&groupId={groupId}&pageNo={pageNo}&pageCount={pageCount}'
    },
    player:{
        getDetail : '/api/player/detail?id={id}',
        vote: '/api/activity/voting',
        entry: '/api/player/entry/add',
    },
    oss:{
        getEntryToken: '/api/oss/getEntryToken',
    },
    topic: {
        getDetail : '/api/topic/{id}/detail',
    },
    visit:{
        addVisit : '/api/activity/visit',
    },
    wechat:{
        authUrl : '/api/wx/auth/url',
        authLogin : '/api/wx/auth/login',
        signature : '/api/wx/js/signature',
    },
    graphCode: '/api/graphCode?code={code}',
};

export {api}
