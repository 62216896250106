<template>
  <div class="tp-banner">
    <van-swipe :autoplay="3000" @change="swipeEvent">
      <van-swipe-item v-for="(item,index) in banners" :key="item.id" @click="bindJumpChange(item)">
        <div :class="{'item-auto':autoHeight,'active':swipeIndex===index}">
          <img :src='item.shufflingImageUrl | ossFileFilter' alt=""/>
        </div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
export default {
  name: 'YdBanner',
  data() {
    return {
      autoHeight: true,
      swipeIndex: 0
    }
  },
  props: ['banners'],

  methods: {
    swipeEvent(index) {
      this.swipeIndex = index
    },
    bindJumpChange(item) {
      //点击进入相关链接
      let url = item.jumpUrl;
      if (url) {
        window.location.href = url;
      }
    },
  }
}
</script>
<style lang="less" scoped>
.tp-banner {
  width: 100%;

  .van-swipe-item {
    height: auto;
  }

  .item-auto {
    opacity: 0;
    height: 0;
  }

  .item-auto.active {
    transition: all 0.5s ease-out;;
    opacity: 1;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>
