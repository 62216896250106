<template>
  <van-dialog v-model="showDialog" @cancel="cancel"
              show-cancel-button :show-confirm-button="false" cancel-button-text="关闭"
              :lazy-render="false" messageAlign="center" cancelButtonColor="#666">
    <div class="QR-img">
      <div class="bjz-img-100" ref="currUrlQrCodeUrl"></div>
    </div>
    <div style="text-align:center;padding: 5px;color:#666;">
      请使用微信扫码后操作
    </div>
  </van-dialog>
</template>

<script>
import store from '../store/store'
import {commonUtil} from "@/js/commonUtil";

export default {
  name: 'AuthWxDialog',
  data() {
    return {}
  },
  props: ['showDialog'],
  watch: {
    showDialog: {
      handler: function (val) {
        if (val === true) {
          this.$refs.currUrlQrCodeUrl.innerHTML = "";
          let url = commonUtil.addUrlParams(window.location.href, 'activityId', store.state.activity.id);
          commonUtil.createQrCode(this.$refs.currUrlQrCodeUrl, url, 150, 150);
        }
      },
    }
  },
  methods: {
    cancel() {
      this.$emit('onCancel')
    },
  }
}
</script>
<style scoped lang='less'>
.QR-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
}
</style>
