<template>
  <tp-vote/>
</template>

<script>
import store from '../store/store'

let tpId = store.state.themes.templateID;
let tpVote = () => import("@/components/template/model" + tpId + "/vote.vue")
export default {
  name: 'vote',
  components: {
    tpVote
  },
}
</script>
