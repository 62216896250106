


let config = {

    imgUrlHost: process.env.VUE_APP_OSS_URL,
    websiteUrl: process.env.VUE_APP_WEBSITE_URL,
    service_host: "http://" + window.location.host,

}


export {config}
