<template>
  <div></div>
</template>

<script>
import Vue from 'vue';
import {Skeleton} from 'vant';
import {commonUtil} from "@/js/commonUtil";
import {ajaxUtil} from '@/js/ajaxUtil'
import {api} from "@/js/api";

Vue.use(Skeleton);
export default {
  name: 'wxAuthCallBack',
  data() {
    return {}
  },
  created() {
    let params = commonUtil.getUrlParams();
    if (params.activityId == null || params.code == null || params.sld == null) {
      return;
    }
    //根据code获取用户信息
    ajaxUtil.ajaxJson(api.wechat.authLogin, {code: params.code}, 'POST').then(data => {
      let {nickname, openId, token} = data
      let baseUrl = window.location.protocol + '//' + params.sld + '.' + process.env.VUE_APP_WX_BACK
      // let baseUrl = window.location.protocol+'//' + process.env.VUE_APP_WX_BACK
      let paramsUrl = '?activityId=' + params.activityId + '&nickname=' + nickname + '&openId=' + openId + '&token=' + token
      if (params.p) {
        paramsUrl += '&p=' + params.p
      }
      //调往初始化页面
      window.location.replace(baseUrl + paramsUrl);
    }, err => {
      this.$router.push("/error");
    })
  },
  methods: {}
}
</script>
<style scoped lang='less'>
</style>
