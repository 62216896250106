import CryptoJS from 'crypto-js'
import {MD5Util} from '../js/MD5Util'

let keyString='YUN_VOTE_PRODUCT';

export function encode(word) {
    var currentTimestamp = new Date().getTime();

    var sKey = CryptoJS.enc.Utf8.parse(MD5Util.hex_md5(keyString));
    var sContent = CryptoJS.enc.Utf8.parse(word + "&" + currentTimestamp);
    let encrypted = CryptoJS.AES.encrypt(sContent, sKey, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
    return encrypted.toString();
}