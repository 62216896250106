<template>
  <van-popup v-model="show" class='yd-vote-captcha' round :close-on-click-overlay="false">
    <div class='vote-captcha'>
      <div class="captcha-title">
        验证码
      </div>
      <div class="captcha-code">
        <canvas width="160" height="60" id="codeCanvas"></canvas>
      </div>
      <div class="captcha-input">
        <input v-model="inputCode" type="text" placeholder="请输入验证码">
      </div>
      <div class="captcha-btn">
        <van-button type="default" class="cancel" @click="cancel">取消</van-button>
        <van-button type="default" class="vote" @click="submit">投票</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import Captcha from 'captcha-mini'

export default {
  name: 'YdVoteCaptcha',
  data() {
    return {
      captcha: '',
      verifyCode: '',
      inputCode: '',
    }
  },
  props: ['show'],
  created() {
    this.initCaptcha()
  },
  watch: {
    show: {
      handler: function (val) {
        if (val) {
          this.inputCode = '';
          this.verifyCode = '';
          this.getVerifyCode()
        }
      },
    }
  },
  methods: {
    initCaptcha() {
      this.captcha = new Captcha({
        lineWidth: 1,   //线条宽度
        lineNum: 8,       //线条数量
        dotNum: 0,       //点的数量
        preGroundColor: [30, 100],    //前景色区间
        backGroundColor: [200, 250], //背景色区间
        fontSize: 30,           //字体大小
        fontWeight: 1000,
        fontFamily: ['Arial'],  //字体类型
        fontStyle: 'fill',      //字体绘制方法，有fill和stroke
        content: 'ABCDEFGHKMNPQRSTUVWXY3456789',  //验证码内容
        length: 4    //验证码长度
      });
    },
    getVerifyCode() {
      let captcha = this.captcha;
      if (!captcha) {
        this.initCaptcha()
      }
      this.$nextTick(() => {
        this.captcha.draw(document.querySelector('#codeCanvas'), code => {
          this.verifyCode = code
        });
      })
    },
    cancel() {
      this.inputCode = '';
      this.$emit('onCancel')
    },
    submit() {
      let inputCode = this.inputCode.trim();
      if (!inputCode) {
        this.$toast('验证码不能为空!');
        return;
      }
      if (inputCode.toUpperCase() !== this.verifyCode) {
        this.$toast('验证码错误!');
        this.getVerifyCode()
        return;
      }
      this.$emit('onSubmit');
    }
  }
}
</script>
<style lang="less" scoped>
.yd-vote-captcha {
  background-color: transparent !important;

  .vote-captcha {
    width: 5.2rem;
    font-size: 0.32rem;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 0.2rem;
    overflow: hidden;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;

    .captcha-title {
      font-weight: bold;
      text-align: center;
      height: 1.2rem;
      line-height: 1.2rem;
    }

    .captcha-code {
      img {
        width: 2rem;
      }
    }

    .captcha-input {
      box-sizing: border-box;
      padding: 0.18rem 0;

      input {
        padding: 0.14rem 0.18rem;
        width: 3rem;
        font-size: 0.28rem;
        border: 1px solid #e5ebf4;
        border-radius: 0.1rem;
      }
    }

    .captcha-btn {
      width: 80%;
      box-sizing: border-box;
      padding: 0.18rem 0 0.26rem 0;
      display: flex;
      justify-content: space-between;

      .van-button {
        width: 1.6rem;
        height: 0.6rem;
        border-radius: 0.1rem;
        font-size: 0.26rem;
      }
    }
  }
}
</style>
